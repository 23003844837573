import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import { updateProviderDetails } from 'actions/preferences';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiFormHelperText-root': {
      width: 500,
    },
  },
}));

function Alert(props) {
  /* eslint-disable react/jsx-props-no-spreading */
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/* eslint-disable no-shadow */
const TaxFormDialog = ({ open, setOpen, updateProviderDetails }) => {
  const classes = useStyles();
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [gstHstNumber, setGstHstNumber] = useState('');
  const [pstNumber, setPstNumber] = useState('');
  const [validGstHstNumber, setValidGstHstNumber] = useState(true);
  const [validPstNumber, setValidPstNumber] = useState(true);

  const handlePstNumber = e => {
    setPstNumber(e.target.value);

    const reg = /^[a-z0-9-]+$/i;

    setValidPstNumber(reg.test(e.target.value));
  };

  const handleGstHstNumber = e => {
    setGstHstNumber(e.target.value);

    const reg = /^\d{9}[A-Za-z]{2}\d{4}$/i;

    setValidGstHstNumber(reg.test(e.target.value));
  };

  const handleSnackClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };

  const handleSubmit = () => {
    updateProviderDetails(
      {
        gst_hst_number: gstHstNumber,
        pst_number: pstNumber,
      },
      () => {
        setSnackOpen(true);
        setOpen(false);
      },
    );
  };

  return (
    <div>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert onClose={handleSnackClose} severity="success">
          <FormattedMessage defaultMessage="Tax information updated!" />
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <FormattedMessage defaultMessage="Update Tax Information" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage
              defaultMessage="You will need to provide a registered tax number to receive your payout and appropriate tax for your clinical services. If you are not registered to charge taxes for your services, please mark yourself as tax exempt on the <a>Clinical and Practice Details page</a>."
              values={{
                a: text => (
                  <Link to="preferences/clinical_details">{text}</Link>
                ),
              }}
            />
          </DialogContentText>
          <div style={{ width: 255 }}>
            <div>
              <TextField
                autoFocus
                classes={{
                  root: classes.root,
                }}
                InputLabelProps={{ shrink: true }}
                margin="dense"
                id="gst_hst_number"
                label={<FormattedMessage defaultMessage="HST or GST Number" />}
                onChange={e => handleGstHstNumber(e)}
                value={gstHstNumber}
                error={!validGstHstNumber}
                /* eslint-disable formatjs/no-literal-string-in-jsx */
                placeholder="123456789RT0001"
                fullWidth
                helperText={
                  !validGstHstNumber && (
                    <FormattedMessage defaultMessage="Your HST or GST number must have the format 123456789RT0001" />
                  )
                }
                required
              />
            </div>
            <div>
              <TextField
                InputLabelProps={{ shrink: true }}
                margin="dense"
                id="pst_number"
                label={
                  <FormattedMessage defaultMessage="PST, RST, or QST Number" />
                }
                onChange={e => handlePstNumber(e)}
                error={!validPstNumber}
                value={pstNumber}
                fullWidth
                helperText={
                  !validPstNumber && (
                    <FormattedMessage defaultMessage="Invalid format" />
                  )
                }
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            <FormattedMessage defaultMessage="Cancel" />
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={!validGstHstNumber || gstHstNumber === ''}
            color="primary"
          >
            <FormattedMessage defaultMessage="Save" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

TaxFormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  updateProviderDetails: PropTypes.func.isRequired,
};

export default connect(null, {
  updateProviderDetails,
})(TaxFormDialog);

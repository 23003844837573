/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Fullscreen from 'react-full-screen';
import Video, {
  createLocalAudioTrack,
  createLocalVideoTrack,
} from 'twilio-video';
import { connect } from 'react-redux';

import localeMessage from '../localeMessage';
import FullscreenIcon from './full.png';
import {
  initRoom,
  toggleCamera,
  toggleMic,
  networkColor,
  networkQuality,
  printNetworkQualityStats,
  detectHardware,
} from '../../twilioHelpers';
import { API_URL, COUNTRY } from '../../../../environment';
import { headers } from '../../../../actions/defaults';

class Screen extends Component {
  constructor(props) {
    super(props);
    /* eslint-disable react/no-unused-state */
    this.state = {
      cameraOpen: true,
      audioOpen: true,
      fullScreen: false,
      participantIsVoice: false,
      networkQualityLevel: '',
      partnerPresent: false,
      practitionerPresent: false,
      twilioVoiceToken: '',
      showYouAreNowConnectedMessage: false,
      localAudioTrackId: 'initial',
      localVideoTrackId: 'initial',
    };
    /* eslint-enable react/no-unused-state */
    this.channel = props.channel;
  }

  componentDidMount() {
    const {
      callbackForTwillio,
      userId,
      couplesSameLocation,
      appointment,
    } = this.props;

    detectHardware.bind(this)();

    axios
      .get(
        `${API_URL}/practice/twilio_videos/token?room_id=${this.channel}&user_id=${userId}&same_location=${couplesSameLocation}`,
        headers(),
      )
      .then(results => {
        const { token, voice_token } = results.data;
        const { sessionType, couplesSameLocation, locale } = this.props;

        this.setState({
          twilioVoiceToken: voice_token,
        });

        let tracks = [];

        Video.connect(token, {
          networkQuality: {
            local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
            remote: 2, // RemoteParticipants' Network Quality verbosity [0 - 3]
          },
          audio: false,
        }).then(room => {
          initRoom.bind(this)(room);

          room.localParticipant.setNetworkQualityConfiguration({
            local: 2,
            remote: 1,
          });

          if (appointment) {
            if (appointment.modality === 'Video') {
              if (
                room.localParticipant.audioTracks.size > 0 &&
                room.localParticipant.videoTracks.size > 0 &&
                room.state === 'connected'
              ) {
                this.setState({ showYouAreNowConnectedMessage: true });
              }
            } else if (appointment.modality === 'Voice') {
              if (
                room.localParticipant.audioTracks.size > 0 &&
                room.state === 'connected'
              ) {
                this.setState({ showYouAreNowConnectedMessage: true });
              }
            }
          }
          if (this.state.showYouAreNowConnectedMessage) {
            setTimeout(() => {
              this.setState({ showYouAreNowConnectedMessage: false });
            }, 3000);
          }

          room.participants.forEach(participant => {
            if (sessionType === 'couples' && !couplesSameLocation) {
              if (
                participant.identity.split('-')[1] === 'guest' ||
                participant.identity.split('-')[1] === 'host'
              ) {
                this.setState({
                  partnerPresent: true,
                });
              } else if (participant.identity.split('-')[1] === 'provider') {
                this.setState({
                  practitionerPresent: true,
                });
              }
            } else {
              if (participant) {
                this.setState({
                  practitionerPresent: true,
                });
              }
            }
          });

          printNetworkQualityStats.bind(this)(
            room.localParticipant.networkQualityLevel,
            room.localParticipant.networkQualityStats,
          );

          room.on('participantDisconnected', participant => {
            this.setState({
              room: this.state.room,
            });
            if (sessionType === 'couples' && !couplesSameLocation) {
              if (
                participant.identity.split('-')[1] === 'guest' ||
                participant.identity.split('-')[1] === 'host'
              ) {
                this.setState({
                  partnerPresent: false,
                });
              } else if (participant.identity.split('-')[1] === 'provider') {
                this.setState({
                  practitionerPresent: false,
                });
              }
            } else {
              this.setState({
                practitionerPresent: false,
              });
            }
          });

          room.on('participantConnected', participant => {
            this.setState({
              room: this.state.room,
            });
            if (sessionType === 'couples' && !couplesSameLocation) {
              if (
                participant.identity.split('-')[1] === 'guest' ||
                participant.identity.split('-')[1] === 'host'
              ) {
                this.setState({
                  partnerPresent: true,
                });
              } else if (participant.identity.split('-')[1] === 'provider') {
                this.setState({
                  practitionerPresent: true,
                });
              }
            } else {
              this.setState({
                practitionerPresent: true,
              });
            }
          });

          room.localParticipant.on(
            'networkQualityLevelChanged',
            printNetworkQualityStats.bind(this),
          );

          callbackForTwillio({
            toggleCamera: toggleCamera.bind(this),
            toggleMic: toggleMic.bind(this),
          });
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { appointment } = this.props;
    const { localAudioTrackId, localVideoTrackId } = this.state;

    if (prevState.localAudioTrackId === 'initial') {
      if (prevState.localAudioTrackId !== this.state.localAudioTrackId) {
        // For video sessions, 'you are now connected' message will be displayed when valid localAudioTrackId && localVideoTrackId sent from twilioHelper.js
        if (appointment.modality === 'Video') {
          if (
            localAudioTrackId !== 'initial' &&
            localVideoTrackId !== 'initial'
          ) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ showYouAreNowConnectedMessage: true }, () => {
              // 'you are now connected' message will be displayed for 3 seconds.
              setTimeout(() => {
                this.setState({ showYouAreNowConnectedMessage: false });
              }, 3000);
            });
          }
          // For voice sessions, 'you are now connected' message will be displayed when valid localAudioTrackId && localVideoTrackId sent from twilioHelper.js
        } else if (appointment.modality === 'Voice') {
          if (localAudioTrackId !== 'initial') {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ showYouAreNowConnectedMessage: true }, () => {
              // 'you are now connected' message will be displayed for 3 seconds.
              setTimeout(() => {
                this.setState({ showYouAreNowConnectedMessage: false });
              }, 3000);
            });
          }
        }
      }
    }
  }

  toggleFullScreen = () => {
    this.setState(prevState => ({
      fullScreen: !prevState.fullScreen,
    }));
  };

  renderUnableMessage = () => {
    const {
      networkQualityLevel,
      hardwareErrorCode,
      hardwareError,
    } = this.state;
    const { locale } = this.props;

    return (
      <p
        className={
          hardwareErrorCode
            ? 'unable-connect-message unable-connect-message--error'
            : 'unable-connect-message'
        }
      >
        {localeMessage('unableTo', locale)}
        <b
          onClick={() => window.location.reload()}
          style={{ textDecoration: 'underline' }}
        >
          {localeMessage('here', locale)}
        </b>
        <span
          className="network-quality"
          style={{ color: networkColor(networkQualityLevel) }}
        >
          {networkQuality[networkQualityLevel]}
        </span>
        <span
          style={{
            fontWeight: 'bold',
            display: 'block',
            marginTop: '20px',
          }}
        >
          {hardwareError}
        </span>
      </p>
    );
  };

  renderPhoneNumber = () => {
    if (this.props.locale === 'en') {
      return COUNTRY === 'US'
        ? `+1 833 934 2673 and use pin ${this.state.twilioVoiceToken} followed by #`
        : `+1 833 461 0480 and use pin ${this.state.twilioVoiceToken} followed by #`;
    }
    return `+1 833 461 0480 et utilisez le NIP 00016049 suivie par #`;
  };

  renderCouplesRemotes = () => {
    const { sessionType, couplesSameLocation, locale } = this.props;
    const { twilioVoiceToken } = this.state;

    if (sessionType !== 'couples' || couplesSameLocation) {
      return <div />;
    }

    return (
      <div className="remote-couples">
        <div id="remote-provider-media" className="remote-couples-media">
          {this.state.practitionerPresent === true ? (
            <h1>
              {localeMessage('yourProviderIssue', locale)}
              <br></br>
              {localeMessage('uniqueDialInCode', locale)}
              {this.renderPhoneNumber()}
              {localeMessage('contactSupport', locale)}
            </h1>
          ) : (
            <h1>{localeMessage('yourCare', locale)}</h1>
          )}
        </div>
        <div id="remote-partner-media" className="remote-couples-media">
          {this.state.partnerPresent === true ? (
            <h1>
              {localeMessage('yourPartnerIssue', locale)}
              <br></br>
              {localeMessage('uniqueDialInCode', locale)}
              {this.renderPhoneNumber()}
              {localeMessage('contactSupport', locale)}
            </h1>
          ) : (
            <h1>{localeMessage('yourCare', locale)}</h1>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { sessionType, couplesSameLocation, locale } = this.props;
    const { practitionerPresent } = this.state;

    return (
      <div className="not-connected-screen">
        <Fullscreen
          enabled={this.state.fullScreen}
          onChange={isFull => this.setState({ fullScreen: isFull })}
        >
          <div id="remote-media-div" className="full-screenable-node">
            <h1
              style={{
                display:
                  sessionType === 'couples' && !couplesSameLocation
                    ? 'none'
                    : 'block',
              }}
            >
              {practitionerPresent ? (
                <h1>
                  {localeMessage('yourProviderIssue', locale)}
                  <br></br>
                  {localeMessage('uniqueDialInCode', locale)}
                  {this.renderPhoneNumber()}
                  {localeMessage('contactSupport', locale)}
                </h1>
              ) : (
                localeMessage('yourCare', locale)
              )}
            </h1>
            {this.renderCouplesRemotes()}
            <div id="local-media" />
            <img
              role="presentation"
              src={FullscreenIcon}
              alt="full-screen"
              className="full-screen-icon"
              onClick={this.toggleFullScreen}
            />
            {this.state.showYouAreNowConnectedMessage && (
              <div className="you-are-now-connected-message-box">
                <p>{localeMessage('youAreNowConnected', locale)}</p>
              </div>
            )}
            {this.renderUnableMessage()}
          </div>
        </Fullscreen>
      </div>
    );
  }
}

Screen.propTypes = {
  callbackForTwillio: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  channel: PropTypes.string.isRequired,
  sessionType: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  couplesSameLocation: PropTypes.bool,
};

Screen.defaultProps = {
  couplesSameLocation: false,
};

function mapStateToProps(state) {
  return {
    locale: (state.user && state.user.locale) || 'en',
  };
}

export default connect(mapStateToProps)(Screen);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import BottomBar from '../BottomBar';
import CoffeeChat from '../../../../images/onboarding/practitioner/coffeechat.svg';
import { scrollToTop } from '../scroll';

class Introduction extends Component {
  componentDidMount() {
    scrollToTop();
  }

  render() {
    const email = this.props.intl.formatMessage({
      defaultMessage: 'practitionernetwork@inkblottherapy.com',
    });
    return (
      <div>
        <CoffeeChat className="coffee-chat" style={{ marginBottom: '40px' }} />
        <h1 style={{ marginBottom: '40px' }}>
          <FormattedMessage defaultMessage="Welcome to the GreenShield Health Practitioner Portal!" />
        </h1>
        <p>
          <FormattedMessage defaultMessage="The next step is to complete your practitioner profile and account by adding your personal, professional, and practice details. Once that is complete, a member of the practitioner network team will be in touch to verify the information you have provided." />
        </p>
        <p>
          <FormattedMessage
            defaultMessage="If you have any questions, please contact {link}."
            values={{
              link: <a href={`mailto:${email}`}>{email}</a>,
            }}
          />
        </p>
        <BottomBar {...this.props} />
      </div>
    );
  }
}

Introduction.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Introduction);

// EN: Welcome to the GreenShield Health Practitioner Portal! The next step is to complete your practitioner profile and account by adding your personal, professional, and practice details. Once that is complete, a member of the practitioner network team will be in touch to verify the information you have provided. If you have any questions, please contact practitionernetwork@inkblottherapy.com.

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const errorStyles = {
  position: 'absolute',
  color: '#f44336',
  fontSize: '12px',
  fontWeight: '600',
  letterSpacing: '0.1px',
  bottom: '0px',
};

function RadioButton({ input, meta, options, label, required }) {
  const { name, value, onChange } = input;
  const { touched, error } = meta;

  const className = required ? 'onboarding-label-required' : 'onboarding-label';
  return (
    <div className="radio-button-group">
      <p className={className}>{label}</p>
      {_.map(options, (option, i) => (
        <label key={i}>
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={value.toString() === option.value.toString()}
            onChange={onChange}
          />
          <span />
          <p>{option.label}</p>
        </label>
      ))}
      {touched && error && <p style={errorStyles}>{error}</p>}
    </div>
  );
}

RadioButton.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  options: PropTypes.array,
  label: PropTypes.string,
  required: PropTypes.bool,
};

RadioButton.defaultProps = {
  input: {},
  meta: {},
  options: [],
};

export default RadioButton;

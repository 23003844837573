import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import BottomBar from '../BottomBar';
import MultiSelectCheckbox from '../form/MultiSelectCheckbox';
import GeneralAvailability from '../form/GeneralAvailability';
import { modalities } from '../helpers';
import Checkbox from '../form/Checkbox';
import {
  updateGeneralAvailability,
  updateCounsellingPreference,
  updateAgeGroups,
} from '../../../actions/preferences';
import { ADVANCE_REGISTRATION, logEvent } from '../../../utils/amplitude';

class PracticeDetails2 extends Component {
  onSubmit = values => {
    const errorMessage = (
      <FormattedMessage defaultMessage="There was a problem updating your practice details." />
    );
    const counsellingPreferenceValues = {
      counselling_preference: {
        modalities: values.modalities,
      },
    };
    const generalAvailabilityValues = {
      general_availability: {
        weekday_day_yn: values.general_availability.includes('weekdays_day'),
        weekday_night_yn: values.general_availability.includes(
          'weekdays_night',
        ),
        weekend_day_yn: values.general_availability.includes('weekends_day'),
        weekend_night_yn: values.general_availability.includes(
          'weekends_night',
        ),
      },
    };
    const ageGroupValues = {
      age_groups: values.age_groups,
    };

    this.props.openLoadingPanel(
      <FormattedMessage defaultMessage="Updating practice details..." />,
    );
    Promise.all([
      this.props.updateCounsellingPreference(counsellingPreferenceValues),
      this.props.updateGeneralAvailability(generalAvailabilityValues),
      this.props.updateAgeGroups(ageGroupValues),
    ])
      .then(() => {
        logEvent(ADVANCE_REGISTRATION, {
          page: 'practice details 2',
        });
        this.props.closeLoadingPanel();
        this.props.nextPage();
      })
      .catch(error => this.props.errorCallback(error, errorMessage));
  };

  render() {
    const { ageGroups, handleSubmit, currentSubStep } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="page-title">
          <h1>
            <FormattedMessage defaultMessage="Practice Details" />
          </h1>
          {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
          <div className="sub-page-indicator">{`${currentSubStep + 1}/7`}</div>
        </div>
        <h3 style={{ marginBottom: '25px' }}>
          <FormattedMessage defaultMessage="What is your general availability to see clients?" />
        </h3>
        <div className="input-row">
          <Field component={GeneralAvailability} name="general_availability" />
        </div>
        <h3>
          <FormattedMessage
            defaultMessage="Which modalities would you like to use to take care of clients? (check
          all that apply)"
          />
        </h3>
        <div className="input-row">
          <Field
            component={Checkbox}
            name="modalities"
            options={modalities}
          />
        </div>
        <h3 style={{ marginTop: 20, marginBottom: 10 }}>
          <FormattedMessage defaultMessage="What age group(s) do you feel comfortable counselling?" />
        </h3>
        <div className="input-row">
          <Field
            component={MultiSelectCheckbox}
            name="age_groups"
            options={ageGroups}
          />
        </div>
        <BottomBar {...this.props} />
      </form>
    );
  }
}

PracticeDetails2.propTypes = {
  ageGroups: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  openLoadingPanel: PropTypes.func.isRequired,
  updateCounsellingPreference: PropTypes.func.isRequired,
  updateGeneralAvailability: PropTypes.func.isRequired,
  updateAgeGroups: PropTypes.func.isRequired,
  closeLoadingPanel: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  errorCallback: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  currentSubStep: PropTypes.number.isRequired,
};

function validate(values) {
  const errors = {};

  if (!values.general_availability)
    errors.general_availability = (
      <FormattedMessage defaultMessage="Required" />
    );

  if (_.isEmpty(values.modalities)) {
    errors.modalities = <FormattedMessage defaultMessage="Required" />;
  }

  if (
    (values.age_groups && values.age_groups.length === 0) ||
    !values.age_groups
  ) {
    errors.age_groups = <FormattedMessage defaultMessage="Required" />;
  }

  return errors;
}

export default reduxForm({
  form: 'PracticeOnboardingForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(
  connect(null, {
    updateCounsellingPreference,
    updateGeneralAvailability,
    updateAgeGroups,
  })(PracticeDetails2),
);

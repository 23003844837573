import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import { connect } from 'react-redux';
import Dropdown from '../form/Dropdown';
import FlagButtons from '../form/FlagButtons';
import BottomBar from '../BottomBar';
import { COUNTRY } from '../../../environment';
import { updateLanguages } from '../../../actions/preferences';
import { ADVANCE_REGISTRATION, logEvent } from '../../../utils/amplitude';

class PracticeDetails1 extends Component {
  constructor(props) {
    super(props);

    const secondaryLanguage = COUNTRY === 'US' ? this.spanish : this.french;
    this.mainLanguages = _.concat(this.primaryLanguage, secondaryLanguage);
  }

  primaryLanguage = [
    {
      value: this.props.intl.formatMessage({ defaultMessage: 'English' }),
      label: this.props.intl.formatMessage({ defaultMessage: 'English' }),
    },
  ];

  french = {
    value: this.props.intl.formatMessage({ defaultMessage: 'French' }),
    label: this.props.intl.formatMessage({ defaultMessage: 'French' }),
  };

  spanish = {
    value: this.props.intl.formatMessage({ defaultMessage: 'Spanish' }),
    label: this.props.intl.formatMessage({ defaultMessage: 'Spanish' }),
  };

  onSubmit = values => {
    const errorMessage = (
      <FormattedMessage defaultMessage="There was a problem updating your practice details." />
    );

    const languages = values.other_languages
      ? _.concat(
          values.languages,
          values.other_languages.map(language => language.value),
        )
      : values.languages;
    const languageValues = {
      languages,
    };

    this.props.openLoadingPanel(
      <FormattedMessage defaultMessage="Updating practice details..." />,
    );
    this.props
      .updateLanguages(languageValues)
      .then(() => {
        logEvent(ADVANCE_REGISTRATION, {
          page: 'practice details 1',
        });
        this.props.closeLoadingPanel();
        this.props.nextPage();
      })
      .catch(error => this.props.errorCallback(error, errorMessage));
  };

  render() {
    const { handleSubmit, languages, currentSubStep } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="page-title">
          <h1>
            <FormattedMessage defaultMessage="Practice Details" />
          </h1>
          {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
          <div className="sub-page-indicator">{`${currentSubStep + 1}/7`}</div>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <h3>
            <FormattedMessage defaultMessage="Which language(s) are your comfortable providing treatment in?" />
          </h3>
          <p className="onboarding-label">
            <FormattedMessage defaultMessage="Please select multiple if you are comfortable providing treatment in multiple languages." />
          </p>
        </div>
        <div className="input-row">
          <Field
            component={FlagButtons}
            name="languages"
            options={this.mainLanguages}
          />
        </div>
        <div className="input-row">
          <Field
            component={Dropdown}
            label={<FormattedMessage defaultMessage="Other languages" />}
            multi
            name="other_languages"
            options={languages}
            searchable
          />
        </div>
        <BottomBar {...this.props} />
      </form>
    );
  }
}

PracticeDetails1.propTypes = {
  languages: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  updateLanguages: PropTypes.func.isRequired,
  openLoadingPanel: PropTypes.func.isRequired,
  closeLoadingPanel: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  errorCallback: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  currentSubStep: PropTypes.func.isRequired,
};

const validate = values => {
  const errors = {};

  if (!values.languages)
    errors.languages = (
      <FormattedMessage defaultMessage="Must select at least one language" />
    );

  return errors;
};

export default injectIntl(
  reduxForm({
    form: 'PracticeOnboardingForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  })(connect(null, { updateLanguages })(PracticeDetails1)),
);

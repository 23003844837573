import React from 'react';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const DestroyConfirm = ({ clinicName, closeHandler, nextPage }) => {
  const buttonHandler = () => {
    nextPage();
    closeHandler();
  };

  return (
    <div className="panel-content">
      <div style={{ marginBottom: '20px' }}>
        <CheckCircleOutlineIcon style={{ color: green[800], fontSize: 48 }} />
        <br />
        <h2>
          <FormattedMessage
            defaultMessage="You have been removed from {clinicName}"
            values={{ clinicName }}
          />
        </h2>
      </div>
      <div className="bottom-bar">
        <div className="flex-left">
          <Button
            className="rounded-button-teal"
            type="button"
            onClick={buttonHandler}
          >
            <div>
              <span>
                <FormattedMessage defaultMessage="Continue" />
              </span>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};
DestroyConfirm.propTypes = {
  clinicName: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
};

export default DestroyConfirm;

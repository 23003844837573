import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import BottomBar from '../BottomBar';
import { yesNo } from '../helpers';
import RadioButton from '../form/RadioButton';
import { COUNTRY } from '../../../environment';
import {
  updateCounsellingPreference,
  updateUsaDetails,
} from '../../../actions/preferences';
import { ADVANCE_REGISTRATION, logEvent } from '../../../utils/amplitude';

export const counsellingPreferences = [
  {
    fieldName: 'psychiatric_medication_yn',
    title: (
      <FormattedMessage defaultMessage="Do you prescribe or adjust psychiatric medications?" />
    ),
  },
  {
    fieldName: 'psychiatric_diagnosis_yn',
    title: (
      <FormattedMessage defaultMessage="Do you make formal psychiatric diagnoses?" />
    ),
  },
  {
    fieldName: 'couples_counselling_yn',
    title: (
      <FormattedMessage defaultMessage="Do you provide couples or family counselling?" />
    ),
  },
  {
    fieldName: 'group_counselling_yn',
    title: (
      <FormattedMessage defaultMessage="Would you be interested in providing group counselling?" />
    ),
  },
  {
    fieldName: 'onsite_counselling_yn',
    title: (
      <FormattedMessage defaultMessage="Would you be interested in on-site support counselling for companies near you?" />
    ),
    subTitle: (
      <FormattedMessage defaultMessage="(e.g. when an employee is being let go; or if bad news is being told to staff)" />
    ),
  },
  {
    fieldName: 'crisis_support_yn',
    title: (
      <FormattedMessage defaultMessage="Would you be interested in being on-call for individual crisis support?" />
    ),
    subTitle: (
      <FormattedMessage defaultMessage="(e.g. a client is feeling suicidal and requires de-escalation)" />
    ),
  },
  {
    fieldName: 'program_development_yn',
    title: (
      <FormattedMessage defaultMessage="Are you interested in being involved in program development for GreenShield Health?" />
    ),
    subTitle: (
      <FormattedMessage defaultMessage="(e.g. creating a peer-support program/educational material for companies or schools)" />
    ),
  },
  {
    fieldName: 'ata_course_yn',
    title: (
      <FormattedMessage defaultMessage="Have you completed the American Telemedicine Association course?" />
    ),
    onlyUs: true,
  },
];
class PracticeDetails6 extends Component {
  onSubmit = values => {
    const errorMessage = (
      <FormattedMessage defaultMessage="There was a problem updating your practice details." />
    );
    const counsellingPreferenceValues = {
      counselling_preference: {
        couples_counselling_yn: values.couples_counselling_yn,
        group_counselling_yn: values.group_counselling_yn,
        onsite_counselling_yn: values.onsite_counselling_yn,
        crisis_support_yn: values.crisis_support_yn,
        program_development_yn: values.program_development_yn,
        psychiatric_medication_yn: values.psychiatric_medication_yn,
        psychiatric_diagnosis_yn: values.psychiatric_diagnosis_yn,
      },
    };
    const usaDetailValues = {
      ata_course_yn: values.ata_course_yn,
    };

    this.props.openLoadingPanel(
      <FormattedMessage defaultMessage="Updating practice details..." />,
    );
    Promise.all([
      this.props.updateCounsellingPreference(counsellingPreferenceValues),
      this.props.updateUsaDetails(usaDetailValues),
    ])
      .then(() => {
        logEvent(ADVANCE_REGISTRATION, {
          page: 'practice details 6',
        });
        this.props.closeLoadingPanel();
        this.props.nextPage();
      })
      .catch(error => this.props.errorCallback(error, errorMessage));
  };

  render() {
    const { handleSubmit, currentSubStep } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="page-title">
          <FormattedMessage tagName="h1" defaultMessage="Practice Details" />
          {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
          <div className="sub-page-indicator">{`${currentSubStep + 1}/7`}</div>
        </div>
        {counsellingPreferences.map(
          ({ fieldName, title, subTitle, onlyUs }) => {
            if (COUNTRY !== 'US' && onlyUs) {
              return <noscript key={fieldName} />;
            }

            return (
              <div key={fieldName}>
                <h3>{title}</h3>
                <h4>{subTitle}</h4>
                <div className="input-row">
                  <Field
                    component={RadioButton}
                    name={fieldName}
                    options={yesNo}
                  />
                </div>
              </div>
            );
          },
        )}
        <BottomBar {...this.props} />
      </form>
    );
  }
}

PracticeDetails6.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  openLoadingPanel: PropTypes.func.isRequired,
  updateCounsellingPreference: PropTypes.func.isRequired,
  updateUsaDetails: PropTypes.func.isRequired,
  closeLoadingPanel: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  errorCallback: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  currentSubStep: PropTypes.number.isRequired,
};

const validate = values => {
  const errors = {};

  if (!values.couples_counselling_yn) {
    errors.couples_counselling_yn = (
      <FormattedMessage defaultMessage="Required" />
    );
  }
  if (!values.group_counselling_yn)
    errors.group_counselling_yn = (
      <FormattedMessage defaultMessage="Required" />
    );
  if (!values.onsite_counselling_yn)
    errors.onsite_counselling_yn = (
      <FormattedMessage defaultMessage="Required" />
    );

  if (!values.crisis_support_yn)
    errors.crisis_support_yn = <FormattedMessage defaultMessage="Required" />;

  if (!values.program_development_yn) {
    errors.program_development_yn = (
      <FormattedMessage defaultMessage="Required" />
    );
  }
  if (!values.psychiatric_medication_yn) {
    errors.psychiatric_medication_yn = (
      <FormattedMessage defaultMessage="Required" />
    );
  }
  if (!values.psychiatric_diagnosis_yn) {
    errors.psychiatric_diagnosis_yn = (
      <FormattedMessage defaultMessage="Required" />
    );
  }

  return errors;
};

export default reduxForm({
  form: 'PracticeOnboardingForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(
  connect(null, {
    updateCounsellingPreference,
    updateUsaDetails,
  })(PracticeDetails6),
);

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FlatButton from 'material-ui/FlatButton';
import LocaleSelectMini from 'components/global/LocaleSelectMini';
import {
  PERSONAL_DETAILS,
  THANK_YOU,
  AUTOMATIC_PAYOUTS,
  MATCHING_PREFERENCES,
} from './data';

function BottomBar({
  currentStep,
  nextPage,
  nonFormPage,
  previousPage,
  emailError,
  urlError,
  skipToEnd,
  skipToNext,
  isClinicCounsellor,
}) {
  return (
    <div className="bottom-bar">
      <LocaleSelectMini />
      <div className="flex-center">
        {currentStep !== PERSONAL_DETAILS && currentStep !== THANK_YOU && (
          <div
            className="small-button"
            onClick={previousPage}
            role="presentation"
          >
            <FormattedMessage defaultMessage="Previous" />
          </div>
        )}
        {(currentStep === AUTOMATIC_PAYOUTS ||
          currentStep === MATCHING_PREFERENCES) && (
          <div
            className="small-button skip-button"
            onClick={
              isClinicCounsellor
                ? () => skipToEnd()
                : () => skipToNext(currentStep)
            }
            role="presentation"
          >
            <FormattedMessage defaultMessage="Skip" />
          </div>
        )}
        {nonFormPage ? (
          <FlatButton
            className={`rounded-button-${
              currentStep === THANK_YOU ? 'blue' : 'teal'
            } next-button`}
            label={
              currentStep === THANK_YOU ? (
                <FormattedMessage defaultMessage="Dashboard" />
              ) : (
                <FormattedMessage defaultMessage="Next" />
              )
            }
            onClick={nextPage}
          />
        ) : (
          <FlatButton
            className="rounded-button-teal next-button"
            label={<FormattedMessage defaultMessage="Next" />}
            type={emailError || urlError ? 'button' : 'submit'}
          />
        )}
      </div>
    </div>
  );
}

BottomBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  emailError: PropTypes.string,
  nextPage: PropTypes.func,
  nonFormPage: PropTypes.bool,
  previousPage: PropTypes.func,
  urlError: PropTypes.string,
  skipToEnd: PropTypes.func,
  skipToNext: PropTypes.func,
  isClinicCounsellor: PropTypes.bool,
};

BottomBar.defaultProps = {
  emailError: undefined,
  nextPage: () => {},
  nonFormPage: false,
  previousPage: () => {},
  urlError: undefined,
  skipToEnd: () => {},
};

export default BottomBar;

import React from 'react';
import MUI4Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

const colorCode = buttonColorType => {
  switch (buttonColorType) {
    case 'baby-blue':
      return '#92b4ff';
    case 'red':
      return '#f3655f';
    case 'green':
      return '#78D794';
    default:
      return '#92b4ff';
  }
};

const useStyles = makeStyles({
  root: {
    color: '#f9f9f9',
    fontFamily: '"Source Sans Pro", sans-serif',
    letterSpacing: '0.2px',
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 6,
    backgroundColor: props => colorCode(props.colortype),
    boxShadow: '0 0 20px 0 rgba(130, 167, 249, 0.2)',
    paddingLeft: 16,
    paddingRight: 16,
    '&:hover': {
      backgroundColor: props => colorCode(props.colortype),
    },
    '&:disabled': {
      color: '#CFCFCF',
      backgroundColor: '#E8E8E8',
    },
  },
});

function Button(props) {
  const classes = useStyles(props);
  return (
    <MUI4Button
      className={classes.root}
      disableFocusRipple
      size="large"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

export default Button;

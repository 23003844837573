import React from 'react';
import { createIntl, createIntlCache, FormattedMessage } from 'react-intl';
import French from '../../../lang/fr.json';
import English from '../../../lang/en.json';
import { COUNTRY } from '../../environment';

const intl = () => {
  const cache = createIntlCache();
  const locale = localStorage.getItem('locale') || 'en';
  const messages = locale === 'en' ? English : French;

  return createIntl({ locale, messages }, cache);
};

const { formatMessage } = intl();

export const INTRODUCTION = -1;
export const PERSONAL_DETAILS = 0;
export const LOCATION_DETAILS = 1;
export const TERMS_OF_USE = 2;
export const PROFESSIONAL_DETAILS = 3;
export const PRACTICE_DETAILS = 4;
export const MATCHING_PREFERENCES = 5;
export const TAX_INFORMATION = 6;
export const AUTOMATIC_PAYOUTS = COUNTRY === 'CA' ? 7 : 6;
export const THANK_YOU = COUNTRY === 'CA' ? 8 : 7;

export const steppers = buildSteppers();

function buildSteppers() {
  /* eslint-disable no-shadow */
  const steppers = [
    {
      label: <FormattedMessage defaultMessage="Personal Details" />,
      value: PERSONAL_DETAILS,
    },
    {
      label: <FormattedMessage defaultMessage="Location Details" />,
      value: LOCATION_DETAILS,
    },
    {
      label: <FormattedMessage defaultMessage="Terms of Use" />,
      value: TERMS_OF_USE,
    },
    {
      label: <FormattedMessage defaultMessage="Professional Details" />,
      value: PROFESSIONAL_DETAILS,
    },
    {
      label: <FormattedMessage defaultMessage="Practice Details" />,
      value: PRACTICE_DETAILS,
    },
    {
      label: <FormattedMessage defaultMessage="Matching Preferences" />,
      value: MATCHING_PREFERENCES,
    },
    {
      label: <FormattedMessage defaultMessage="Payment Information" />,
      value: AUTOMATIC_PAYOUTS,
    },
  ];

  if (COUNTRY === 'CA') {
    steppers.push({
      label: <FormattedMessage defaultMessage="Tax Information" />,
      value: TAX_INFORMATION,
    });
  }

  return steppers;
}

export const titles = [
  {
    value: 'Dr',
    label: <FormattedMessage defaultMessage="Dr" />,
  },
  {
    value: 'Mr',
    label: <FormattedMessage defaultMessage="Mr" />,
  },
  {
    value: 'Ms',
    label: <FormattedMessage defaultMessage="Ms" />,
  },
  {
    value: 'Miss',
    label: <FormattedMessage defaultMessage="Miss" />,
  },
  {
    value: 'Mx',
    label: <FormattedMessage defaultMessage="Mx" />,
  },
];

export const genders = [
  {
    value: 'male',
    label: <FormattedMessage defaultMessage="Male" />,
  },
  {
    value: 'female',
    label: <FormattedMessage defaultMessage="Female" />,
  },
  {
    value: 'custom',
    label: <FormattedMessage defaultMessage="Custom" />,
  },
  {
    value: 'unspecified',
    label: <FormattedMessage defaultMessage="Prefer not to say" />,
  },
];

export const countries = [
  { value: 'CAN', label: <FormattedMessage defaultMessage="Canada" /> },
  { value: 'USA', label: <FormattedMessage defaultMessage="United States" /> },
];

export const provinces = [
  { value: 'AB', label: 'Alberta' },
  { value: 'BC', label: 'British Columbia' },
  { value: 'MB', label: 'Manitoba' },
  { value: 'NB', label: 'New Brunswick' },
  { value: 'NL', label: 'Newfoundland and Labrador' },
  { value: 'NT', label: 'Northwest Territories' },
  { value: 'NS', label: 'Nova Scotia' },
  { value: 'NU', label: 'Nunavut' },
  { value: 'ON', label: 'Ontario' },
  { value: 'PE', label: 'Prince Edward Island' },
  { value: 'QC', label: 'Quebec' },
  { value: 'SK', label: 'Saskatchewan' },
  { value: 'YT', label: 'Yukon Territory' },
];

export const states = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const yesNo = [
  {
    value: true,
    label: <FormattedMessage defaultMessage="Yes" />,
  },
  {
    value: false,
    label: <FormattedMessage defaultMessage="No" />,
  },
];

export const bachelorDegrees = [
  { value: 'BA', label: <FormattedMessage defaultMessage="Arts" /> },
  { value: 'BSc', label: <FormattedMessage defaultMessage="Science" /> },
  { value: 'BSW', label: <FormattedMessage defaultMessage="Social Work" /> },
  { value: 'BEd', label: <FormattedMessage defaultMessage="Education" /> },
];

export const mastersDegrees = [
  { value: 'MC', label: <FormattedMessage defaultMessage="Counselling" /> },
  { value: 'MSW', label: <FormattedMessage defaultMessage="Social Work" /> },
  {
    value: 'MScPsych',
    label: <FormattedMessage defaultMessage="Psychology" />,
  },
  { value: 'MEd', label: <FormattedMessage defaultMessage="Education" /> },
  { value: 'MDv', label: <FormattedMessage defaultMessage="Divinity" /> },
  {
    value: 'MOT',
    label: <FormattedMessage defaultMessage="Occupational Therapy" />,
  },
];

export const phds = [
  {
    value: 'PhDPsych',
    label: <FormattedMessage defaultMessage="Psychology" />,
  },
];

export const medical = [
  {
    value: 'GP',
    label: <FormattedMessage defaultMessage="General Practitioner" />,
  },
  { value: 'Psych', label: <FormattedMessage defaultMessage="Psychiatrist" /> },
  {
    value: 'RN/RPN',
    label: <FormattedMessage defaultMessage="Registered Nurse" />,
  },
];

export const diplomas = [
  { value: 'DC', label: <FormattedMessage defaultMessage="Counselling" /> },
  {
    value: 'ICAF',
    label: (
      <FormattedMessage defaultMessage="International Coach Federation Accredited" />
    ),
  },
];

export const referrals = [
  {
    value: 'Email',
    label: <FormattedMessage defaultMessage="Email" />,
  },
  {
    value: 'Colleague, family or friend',
    label: <FormattedMessage defaultMessage="Colleague, family or friend" />,
  },
  {
    value: 'Client',
    label: <FormattedMessage defaultMessage="Client" />,
  },
  { value: 'LinkedIn', label: 'LinkedIn' },
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Instagram', label: 'Instagram' },
  {
    value: 'Job posting',
    label: <FormattedMessage defaultMessage="Job posting" />,
  },
  {
    value: 'Online search',
    label: <FormattedMessage defaultMessage="Online search" />,
  },
  {
    value: 'News',
    label: <FormattedMessage defaultMessage="News" />,
  },
  {
    value: 'Event',
    label: <FormattedMessage defaultMessage="Event" />,
  },
  {
    value: 'Other',
    label: <FormattedMessage defaultMessage="Other" />,
  },
];

export const modalities = [
  { value: 'voice', label: <FormattedMessage defaultMessage="Voice" /> },
  { value: 'video', label: <FormattedMessage defaultMessage="Video" /> },
  {
    value: 'in_person',
    label: <FormattedMessage defaultMessage="In-Person" />,
  },
];

export const normalizeStripeError = stripeError => {
  const localizeStripeError = error => {
    if (error?.param === 'file') {
      return formatMessage({
        defaultMessage:
          "The file type you sent us isn't being recognized. Please try another file.",
      });
    }

    if (error?.code === 'routing_number_invalid') {
      return formatMessage({
        defaultMessage:
          "We couldn't find the bank for that transit/institution number.",
      });
    }

    return formatMessage({
      defaultMessage: 'Something went wrong. Please try again.',
    });
  };

  return localizeStripeError(stripeError);
};

export const toMultiSelectOptions = options =>
  options.map(option => ({
    id: option.id,
    name: option.name,
    label: option.name,
    value: option.id,
  }));

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChevronRight from '@material-ui/icons/ChevronRight';

class MessageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
    };
  }

  onChange = event => {
    this.setState({ message: event.target.value });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    if (this.state.message !== '' && this.props.connected_yn) {
      this.props.onMessageSend(this.state.message);
      this.setState({
        message: '',
      });
    }
  };

  render() {
    const { connected_yn } = this.props;
    return (
      <form className="message-form" name="newMessage" onSubmit={this.handleFormSubmit}>
        <div className="input-container">
          <input
            className="message-input"
            type="text"
            name="message"
            value={this.state.message}
            onChange={this.onChange}
          />
        </div>
        <div className="button-container">
          <ChevronRight
            className={connected_yn ? 'message-send-button' : 'message-send-button-loading'}
            onClick={this.handleFormSubmit}
            style={{
              color: '#fdfdfd',
            }}
          />
        </div>
      </form>
    );
  }
}

MessageForm.propTypes = {
  onMessageSend: PropTypes.func.isRequired,
  connected_yn: PropTypes.bool.isRequired,
};
export default MessageForm;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import axios from 'axios';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';

import BottomBar from '../BottomBar';
import { scrollToTop } from '../scroll';
import Input from '../form/Input';
import FileUpload from '../../global/FileUpload';
import RadioButton from '../form/RadioButton';
import Dropdown from '../form/Dropdown';
import { provinces, states, normalizeStripeError } from '../helpers';
import normalizeDate from '../../../utils/normalizeDate';
import normalizePhone from '../../../utils/normalizePhone';
import {
  required,
  validateZeroNumeric,
  validateZeroNumericDigit3,
  validateZeroNumericDigit5,
  validateZeroNumericDigit9,
} from '../../global/validation';
import LoadingPanel from '../../global/LoadingPanel';
import { STRIPE_KEY, COUNTRY } from '../../../environment';
import { createConnectAccount } from '../../../ducks/payouts/connectAccount';
import ErrorDialog from '../ErrorDialog';
import { uploadDocument } from '../../../actions/preferences';
import { ADVANCE_REGISTRATION, logEvent } from '../../../utils/amplitude';

const options = [
  { value: 'passport', label: <FormattedMessage defaultMessage="Passport" /> },
  {
    value: 'driver_license',
    label: <FormattedMessage defaultMessage="Driver's License" />,
  },
  {
    value: 'other',
    label: <FormattedMessage defaultMessage="Other Government-Issued ID" />,
  },
];

class AutomaticPayouts extends Component {
  constructor() {
    super();

    this.state = {
      loadingPanel: { open: false, message: '' },
      errorDialog: { open: false, message: '', callback: null },
      countryCode: COUNTRY === 'US' ? 'USA' : 'CAN',
    };
  }

  componentDidMount() {
    const { user } = this.props;

    this.props.change('doc_type', 'passport');
    if (user && user.id) {
      this.updateFields();
    }
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (user !== prevProps.user && user.id && !prevProps.user.id) {
      this.updateFields();
    }
  }

  // set up automated payouts and then call nextPage in index.js
  onSubmit = values => {
    scrollToTop();

    const { countryCode } = this.state;

    const locale = localStorage.getItem('locale') || 'en';
    const stripe = Stripe(STRIPE_KEY, { locale });
    const bodyFront = new FormData();
    let frontFileId = '';
    let backFileId = '';
    let verificationObject = {};
    let bankAccountToken = '';
    let accountToken = '';
    let personToken = '';

    const dob = moment.utc(values.date_of_birth, 'DD/MM/YYYY');
    const state =
      typeof values.state === 'string'
        ? values.state
        : _.get(values, 'state.value');

    bodyFront.append('file', values.file_front[0]);
    bodyFront.append('purpose', 'identity_document');

    this.openLoadingPanel(
      <FormattedMessage defaultMessage="Uploading Photo ID Image Files..." />,
    );

    axios
      .post('https://uploads.stripe.com/v1/files', bodyFront, {
        headers: { Authorization: `Bearer ${STRIPE_KEY}` },
      })
      .then(response => {
        frontFileId = response.data.id;

        if (values.doc_type !== 'passport') {
          const bodyBack = new FormData();
          bodyBack.append('file', values.file_back[0]);
          bodyBack.append('purpose', 'identity_document');

          return axios.post('https://uploads.stripe.com/v1/files', bodyBack, {
            headers: { Authorization: `Bearer ${STRIPE_KEY}` },
          });
        }

        return Promise.resolve(true);
      })
      .then(response => {
        if (values.doc_type !== 'passport') {
          backFileId = response.data.id;
        }

        const documentValues = new FormData();
        documentValues.append('document', values.file_front[0]);
        documentValues.append('document_type', 'photo_id');

        return this.props.uploadDocument(documentValues);
      })
      .then(() => {
        if (values.doc_type !== 'passport') {
          verificationObject = {
            document: {
              front: frontFileId,
              back: backFileId,
            },
          };
        } else {
          verificationObject = {
            document: {
              front: frontFileId,
            },
          };
        }

        this.openLoadingPanel(
          <FormattedMessage defaultMessage="Creating Encrypted Tokens..." />,
        );

        const bankAccountValues = {
          country: countryCode === 'USA' ? 'US' : 'CA',
          currency: countryCode === 'USA' ? 'usd' : 'cad',
          routing_number:
            countryCode === 'USA'
              ? values.routing_number
              : `${values.transit_code}${values.bank_code}`,
          account_number: values.account_number,
          account_holder_name: values.account_holder_name,
          account_holder_type: 'individual',
        };

        return stripe.createToken('bank_account', bankAccountValues);
      })
      .then(result => {
        if (result.error) {
          normalizeStripeError(result.error);
          return Promise.reject(result.error);
        }

        bankAccountToken = result.token.id;

        const accountValues = {
          business_type: 'individual',
          individual: {
            first_name: values.first_name,
            last_name: values.last_name,
            phone: values.phone,
            email: values.email,
            id_number: values.personal_id_number,
            dob: {
              day: dob.date(),
              month: dob.month() + 1,
              year: dob.year(),
            },
            address: {
              line1: values.line1,
              line2: values.line2 || '',
              city: values.city,
              state,
              postal_code: values.postal_code,
              country: countryCode === 'USA' ? 'US' : 'CA',
            },
            verification: { ...verificationObject },
          },
          tos_shown_and_accepted: true,
        };
        const personValues = {
          person: {
            relationship: { title: values.job_title },
          },
        };

        return Promise.all([
          stripe.createToken('account', accountValues),
          stripe.createToken('person', personValues),
        ]);
      })
      .then(result => {
        const accountRes = result[0];
        const personRes = result[1];
        if (accountRes.error) {
          return Promise.reject(result.error);
        }
        if (personRes.error) {
          return Promise.reject(personRes.error);
        }
        accountToken = accountRes.token.id;
        personToken = personRes.token.id;

        this.openLoadingPanel(
          <FormattedMessage defaultMessage="Creating Connect Account..." />,
        );

        return new Promise((resolve, reject) => {
          this.props.createConnectAccount(
            {
              country: countryCode === 'USA' ? 'US' : 'CA',
              external_account: bankAccountToken,
              account_token: accountToken,
              person_token: personToken,
            },
            () => {
              resolve();
            },
            error => {
              reject(new Error(error.response.data.message));
            },
          );
        });
      })
      .then(() => {
        logEvent(ADVANCE_REGISTRATION, {
          page: 'payment information',
        });
        this.closeLoadingPanel();
        this.props.nextPage();
      })
      .catch(error => {
        this.closeLoadingPanel();
        const errorMessage = normalizeStripeError(
          error.response?.data?.error || error,
        );
        this.openErrorDialog(errorMessage);
      });
  };

  updateFields = () => {
    const { user } = this.props;

    let phoneNumber = null;

    if (user.office_number && user.office_number.number) {
      phoneNumber = user.office_number.number;
    } else if (user.sms_number && user.sms_number.number) {
      phoneNumber = user.sms_number.number;
    }

    this.props.change('first_name', user.first_name);
    this.props.change('last_name', user.last_name);
    this.props.change('email', user.email);
    this.props.change('phone', phoneNumber);
    this.props.change('account_holder_name', user.full_name);

    if (user.practice_address) {
      this.setState({
        countryCode: user.practice_address.state.country.iso_code,
      });

      this.props.change('line1', user.practice_address.street);
      this.props.change('line2', user.practice_address.alternate_street);
      this.props.change('city', user.practice_address.city);
      this.props.change('state', user.practice_address.state.code);
      this.props.change('postal_code', user.practice_address.code);
      this.props.change('country', user.practice_address.state.country.name);
    }

    if (user.dob && moment.utc(user.dob).isValid()) {
      this.props.change(
        'date_of_birth',
        moment.utc(user.dob).format('DD/MM/YYYY'),
      );
    }
  };

  openLoadingPanel = message =>
    this.setState({ loadingPanel: { open: true, message } });

  closeLoadingPanel = () =>
    this.setState({ loadingPanel: { open: false, message: '' } });

  openErrorDialog = (message, callback) =>
    this.setState({ errorDialog: { open: true, message, callback } });

  closeErrorDialog = () => {
    const { callback } = this.state.errorDialog;

    this.setState(
      { errorDialog: { open: false, message: '', callback: null } },
      () => callback && callback(),
    );
  };

  renderPersonalId = () => {
    const { countryCode } = this.state;
    const { intl } = this.props;
    const { formatMessage } = intl;

    const label =
      countryCode === 'USA' ? (
        <FormattedMessage defaultMessage="Social Security Number" />
      ) : (
        <FormattedMessage defaultMessage="Social Insurance Number" />
      );
    const provinceOptions = countryCode === 'USA' ? states : provinces;

    return (
      <div>
        <FormattedMessage tagName="h3" defaultMessage="Personal Information" />
        <div className="input-row">
          <Field
            required
            name="personal_id_number"
            component={Input}
            label={label}
            validate={[required, validateZeroNumericDigit9]}
          />
          <Field
            required
            name="date_of_birth"
            component={Input}
            label={formatMessage({
              defaultMessage: 'Date of Birth (DD/MM/YYYY)',
            })}
            validate={[required]}
            normalize={normalizeDate}
          />
        </div>
        <div className="input-row">
          <Field
            required
            component={Input}
            label={formatMessage({ defaultMessage: 'Street 1' })}
            name="line1"
            validate={[required]}
          />
          <Field
            component={Input}
            label={<FormattedMessage defaultMessage="Street 2" />}
            name="line2"
            placeholder={this.props.intl.formatMessage({
              defaultMessage: 'Unit, Suite, Apt number',
            })}
          />
          <Field
            component={Input}
            label={formatMessage({
              defaultMessage: 'City/Town',
            })}
            name="city"
            validate={[required]}
          />
        </div>
        <div className="input-row">
          <Field
            required
            component={Dropdown}
            label={formatMessage({
              defaultMessage: 'Province/Territory/State',
            })}
            name="state"
            options={provinceOptions}
            validate={[required]}
          />
          <Field
            component={Input}
            label={formatMessage({
              defaultMessage: 'Postal/Zip Code',
            })}
            name="postal_code"
            validate={[required]}
          />
        </div>
        <div className="input-row">
          <Field
            component={Input}
            label={formatMessage({
              defaultMessage: 'Phone Number',
            })}
            name="phone"
            validate={[required]}
            normalize={normalizePhone}
          />
          <Field
            component={Input}
            label={`${formatMessage({
              defaultMessage: 'Job Title',
            })} *`}
            name="job_title"
            validate={[required]}
          />
        </div>
        <p className="onboarding-sub-label" style={{ marginBottom: '40px' }}>
          <FormattedMessage
            defaultMessage="Required by our payment processor, <a>Stripe</a>"
            values={{
              a: str => (
                <a
                  href="https://stripe.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {str}
                </a>
              ),
            }}
          />
        </p>
      </div>
    );
  };

  renderBankAccount = () => {
    const { countryCode } = this.state;
    const { intl } = this.props;
    const { formatMessage } = intl;

    if (countryCode === 'USA') {
      return (
        <div style={{ marginBottom: '40px' }}>
          <h3 style={{ marginBottom: '20px' }}>
            <FormattedMessage defaultMessage="Bank Account (US)" />
          </h3>
          <div className="input-row">
            <Field
              required
              name="routing_number"
              component={Input}
              label={formatMessage({
                defaultMessage: 'Routing Number',
              })}
              validate={[required, validateZeroNumericDigit9]}
            />
            <Field
              required
              name="account_number"
              component={Input}
              label={formatMessage({
                defaultMessage: 'Account Number',
              })}
              validate={[required, validateZeroNumeric]}
            />
          </div>
          <Field
            required
            name="account_holder_name"
            component={Input}
            label={formatMessage({
              defaultMessage: 'Holder Name',
            })}
            validate={required}
            style={{ marginBottom: '40px' }}
          />
        </div>
      );
    }

    return (
      <div style={{ marginBottom: '40px' }}>
        <h3 style={{ marginBottom: '20px' }}>
          <FormattedMessage defaultMessage="Bank Account (CA)" />
        </h3>
        <div className="input-row">
          <Field
            required
            name="transit_code"
            component={Input}
            label={formatMessage({
              defaultMessage: 'Financial Institution Number',
            })}
            validate={[required, validateZeroNumericDigit5]}
          />
          <Field
            required
            name="bank_code"
            component={Input}
            label={formatMessage({
              defaultMessage: 'Bank Code',
            })}
            validate={[required, validateZeroNumericDigit3]}
          />
          <Field
            required
            name="account_number"
            component={Input}
            label={formatMessage({
              defaultMessage: 'Account Number',
            })}
            validate={[required, validateZeroNumeric]}
          />
        </div>
        <Field
          required
          name="account_holder_name"
          component={Input}
          label={formatMessage({
            defaultMessage: 'Holder Name',
          })}
          validate={required}
          style={{ marginBottom: '40px' }}
        />
      </div>
    );
  };

  renderPhotoID = () => {
    const { docType, intl } = this.props;
    const { formatMessage } = intl;

    const frontLabel =
      docType === 'passport'
        ? formatMessage({ defaultMessage: 'Passport Image File' })
        : formatMessage({ defaultMessage: 'Photo ID Front Image File' });

    return (
      <div>
        <h3>{formatMessage({ defaultMessage: 'Photo ID' })}</h3>
        <p className="onboarding-label-required">
          {formatMessage({ defaultMessage: 'ID Type' })}
        </p>
        <Field
          name="doc_type"
          component={RadioButton}
          validate={required}
          options={options}
        />
        <p className="onboarding-label-required">{frontLabel}</p>
        <Field
          name="file_front"
          component={FileUpload}
          validate={required}
          style={{ marginBottom: '30px' }}
          maxSize={5}
        />
        {docType !== 'passport' && (
          <div>
            <p className="onboarding-label-required">
              {formatMessage({
                defaultMessage: 'Photo ID Back Image File',
              })}
            </p>
            <Field
              name="file_back"
              component={FileUpload}
              validate={required}
              style={{ marginBottom: '30px' }}
              maxSize={5}
            />
          </div>
        )}
      </div>
    );
  };

  renderAgreement = () => (
    <div>
      {this.props.intl.formatMessage(
        {
          tagName: 'p',
          defaultMessage:
            'By setting up your account, you agree to the <a>Stripe Connected Account Agreement</a>.',
        },
        {
          a: title => (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://stripe.com/fr-ca-ca/legal/connect-account"
            >
              {title}
            </a>
          ),
        },
      )}
    </div>
  );

  render() {
    const { loadingPanel, errorDialog } = this.state;

    return (
      <div>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div className="page-title" style={{ marginBottom: '20px' }}>
            <FormattedMessage
              tagName="h1"
              defaultMessage="Payment Information"
            />
          </div>
          <h4 style={{ marginBottom: '40px' }}>
            <FormattedMessage defaultMessage="We need the following information to make payments to your account. This information is confidential and will be deleted if you choose to deactivate your GreenShield Health account" />
          </h4>
          {this.renderPersonalId()}
          {this.renderBankAccount()}
          {this.renderPhotoID()}
          {this.renderAgreement()}
          <BottomBar {...this.props} />
        </form>
        {loadingPanel.open && <LoadingPanel message={loadingPanel.message} />}
        <ErrorDialog
          open={errorDialog.open}
          message={errorDialog.message}
          handleClose={this.closeErrorDialog}
        />
      </div>
    );
  }
}

AutomaticPayouts.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  createConnectAccount: PropTypes.func.isRequired,
  uploadDocument: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  docType: PropTypes.string,
  fileFront: PropTypes.array,
  fileBack: PropTypes.array,
  user: PropTypes.object,
  currentStep: PropTypes.number.isRequired,
};

AutomaticPayouts.defaultProps = {
  docType: '',
  fileFront: [],
  fileBack: [],
  user: {},
};

function mapStateToProps(state) {
  const selector = formValueSelector('PracticeOnboardingForm');

  return {
    user: state.user,
    docType: selector(state, 'doc_type'),
    fileFront: selector(state, 'file_front'),
    fileBack: selector(state, 'file_back'),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    createConnectAccount,
    uploadDocument,
  })(
    reduxForm({
      form: 'PracticeOnboardingForm',
      destroyOnUnmount: false,
      forceUnregisterOnUnmount: true,
    })(AutomaticPayouts),
  ),
);

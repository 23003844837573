import React from 'react';
import PropTypes from 'prop-types';

const inputStyle = {
  fontFamily: 'Source Sans Pro',
  width: '200px',
  height: '40px',
  border: 'none',
  borderRadius: '4px',
  backgroundColor: 'rgba(240, 242, 245, 0.79)',
  outline: 'none',
  paddingLeft: '10px',
  paddingRight: '10px',
  color: '#0f2045',
};

function Input({
  label,
  required,
  input,
  meta,
  placeholder,
  type,
  errorText,
  afterText,
}) {
  const { error, touched } = meta;
  const className = required ? 'onboarding-label-required' : 'onboarding-label';

  return (
    <div style={{ position: 'relative' }}>
      <p className={className}>{label}</p>
      <input
        {...input}
        placeholder={placeholder}
        style={inputStyle}
        type={type}
      />
      <span>{afterText}</span>
      {touched && error && <p className="onboarding-error">{error}</p>}
      {errorText && <p className="onboarding-error">{errorText}</p>}
    </div>
  );
}

Input.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  input: PropTypes.object,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  errorText: PropTypes.string,
  afterText: PropTypes.string,
};

Input.defaultProps = {
  label: undefined,
  required: false,
  input: {},
  meta: {},
  placeholder: undefined,
  type: 'text',
  errorText: undefined,
  afterText: undefined,
};

export default Input;

import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { PAGE_STATES } from './helper';

const ClinicList = ({ clinicName, submitHandler, updateCurrentPage }) => (
  <div className="panel-content">
    <h1>
      <FormattedMessage defaultMessage="Confirm your designated clinic" />
    </h1>
    <h6>
      <FormattedMessage defaultMessage="Is this the clinic you’re associated with?" />
    </h6>
    <Card variant="outlined">
      <CardContent>{clinicName}</CardContent>
    </Card>
    <div className="bottom-bar">
      <div className="flex-left">
        <Button
          className="rounded-button-white"
          onClick={() => updateCurrentPage(PAGE_STATES.FIND_CLINIC)}
        >
          <FormattedMessage defaultMessage="No" />
        </Button>
        <Button
          className="rounded-button-teal next-button"
          type="button"
          onClick={submitHandler}
        >
          <div>
            <span style={{ color: '#fff' }}>
              <FormattedMessage defaultMessage="Confirm" />
            </span>
          </div>
        </Button>
      </div>
    </div>
  </div>
);

ClinicList.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  updateCurrentPage: PropTypes.func.isRequired,
  clinicName: PropTypes.string.isRequired,
};

export default ClinicList;

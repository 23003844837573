/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import Video from 'twilio-video';
import axios from 'axios';
import GlobalLoading from '../../global/LoadingDots';
import { API_URL, COUNTRY } from '../../../environment';
import { headers } from '../../../actions/defaults';
import {
  initRoom,
  toggleCamera,
  toggleMic,
  networkColor,
  networkQuality,
  printNetworkQualityStats,
  detectHardware,
} from '../twilioHelpers';

class TwilioVideo extends PureComponent {
  constructor(props) {
    super(props);
    /* eslint-disable react/no-unused-state */
    this.state = {
      cameraOpen: true,
      audioOpen: true,
      participantIsVoice: false,
      networkQualityLevel: '',
      hardwareError: '',
      clientPresent: false,
      partnerPresent: false,
      coupleTogether: false,
      twilioVoiceToken: '',
      showYouAreNowConnectedMessage: false,
      localAudioTrackId: 'initial',
      localVideoTrackId: 'initial',
    };
    /* eslint-enable react/no-unused-state */
    this.channel = props.channel;
  }

  componentDidMount() {
    const { channel, userId, sessionType, appointment } = this.props;

    detectHardware.bind(this)();

    axios
      .get(
        `${API_URL}/practice/twilio_videos/token?room_id=${channel}&user_id=${userId}`,
        headers(),
      )
      .then(results => {
        const { token, voice_token } = results.data;

        const tracks = [];

        this.setState({
          twilioVoiceToken: voice_token,
        });

        Video.connect(token, {
          networkQuality: {
            local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
            remote: 2, // RemoteParticipants' Network Quality verbosity [0 - 3]
          },
          audio: false,
          name: `${channel}`,
        }).then(room => {
          initRoom.bind(this)(room);

          room.localParticipant.setNetworkQualityConfiguration({
            local: 2,
            remote: 1,
          });

          room.participants.forEach(participant => {
            if (sessionType === 'couples') {
              if (participant.identity.split('-').length > 2) {
                this.setState({
                  coupleTogether: true,
                  clientPresent: true,
                });
              } else if (participant.identity.split('-')[1] === 'guest') {
                this.setState({
                  partnerPresent: true,
                  coupleTogether: false,
                });
              } else if (participant.identity.split('-')[1] === 'host') {
                this.setState({
                  clientPresent: true,
                });
              }
            } else if (participant) {
              this.setState({
                clientPresent: true,
              });
            }
          });

          room.on('participantDisconnected', participant => {
            this.setState({
              room: this.state.room,
            });
            if (
              (sessionType === 'couples' && this.state.coupleTogether) ||
              sessionType !== 'couples'
            ) {
              this.setState({
                clientPresent: false,
                coupleTogether: false,
              });
            } else if (
              sessionType === 'couples' &&
              !this.state.coupleTogether
            ) {
              if (participant.identity.split('-')[1] === 'guest') {
                this.setState({
                  partnerPresent: false,
                });
              } else if (participant.identity.split('-')[1] === 'host') {
                this.setState({
                  clientPresent: false,
                });
              }
            }
          });

          room.on('participantConnected', participant => {
            this.setState({
              room: this.state.room,
            });
            if (
              (sessionType === 'couples' &&
                participant.identity.split('-').length > 2) ||
              sessionType !== 'couples'
            ) {
              const isTogether = sessionType === 'couples';
              this.setState({
                clientPresent: true,
                coupleTogether: isTogether,
              });
            } else if (
              sessionType === 'couples' &&
              participant.identity.split('-').length === 2
            ) {
              if (participant.identity.split('-')[1] === 'guest') {
                this.setState({
                  partnerPresent: true,
                  coupleTogether: false,
                });
              } else if (participant.identity.split('-')[1] === 'host') {
                this.setState({
                  clientPresent: true,
                  coupleTogether: false,
                });
              }
            }
          });

          printNetworkQualityStats.bind(this)(
            room.localParticipant.networkQualityLevel,
            room.localParticipant.networkQualityStats,
          );

          room.localParticipant.on(
            'networkQualityLevelChanged',
            printNetworkQualityStats.bind(this),
          );

          this.props.callbackForTwillio({
            toggleCamera: toggleCamera.bind(this),
            toggleMic: toggleMic.bind(this),
          });
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { appointment } = this.props;
    const { localAudioTrackId, localVideoTrackId } = this.state;

    if (prevState.localAudioTrackId === 'initial') {
      if (prevState.localAudioTrackId !== this.state.localAudioTrackId) {
        // For video sessions, 'you are now connected' message will be displayed when valid localAudioTrackId && localVideoTrackId sent from twilioHelper.js
        if (appointment.modality === 'Video') {
          if (
            localAudioTrackId !== 'initial' &&
            localVideoTrackId !== 'initial'
          ) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ showYouAreNowConnectedMessage: true }, () => {
              // 'you are now connected' message will be displayed for 3 seconds.
              setTimeout(() => {
                this.setState({ showYouAreNowConnectedMessage: false });
              }, 3000);
            });
          }
          // For voice sessions, 'you are now connected' message will be displayed when valid localAudioTrackId && localVideoTrackId sent from twilioHelper.js
        } else if (appointment.modality === 'Voice') {
          if (localAudioTrackId !== 'initial') {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ showYouAreNowConnectedMessage: true }, () => {
              // 'you are now connected' message will be displayed for 3 seconds.
              setTimeout(() => {
                this.setState({ showYouAreNowConnectedMessage: false });
              }, 3000);
            });
          }
        }
      }
    }
  }

  componentWillUnmount() {
    window.location.reload(false);
  }

  renderLoadingMessage = (clientArrived, twilioVoiceToken) => {
    const { intl } = this.props;
    const { formatMessage } = intl;

    const clientWillArriveSoon = (
      <FormattedMessage
        defaultMessage="The person you are waiting for will arrive soon. If you don't see them and it is after the scheduled appointment start time, message them directly in the chat box. For more information, <a>visit the Help Centre</a>"
        values={{
          a: chunks => (
            <a
              href={
                intl.locale === 'en'
                  ? 'https://inkblot.zendesk.com/hc/en-us/articles/360053062011-Troubleshooting-Cannot-connect-to-my-appointment'
                  : 'https://inkblot.zendesk.com/hc/fr-ca/articles/360053062011-Troubleshooting-Cannot-connect-to-my-appointment'
              }
            >
              {chunks}
            </a>
          ),
        }}
      />
    );

    const cameraOnOffUS = formatMessage(
      {
        defaultMessage:
          'It appears that your client either has their camera / microphone off, or is having some hardware issues. You can choose to use your unique dial in code to connect by voice: +1 833 934 2673 and use pin {twilioVoiceToken} followed by # or contact support.',
      },
      { twilioVoiceToken },
    );

    const cameraOnOffCA = formatMessage(
      {
        defaultMessage:
          'It appears that your client either has their camera / microphone off, or is having some hardware issues. You can choose to use your unique dial in code to connect by voice: +1 833 461 0480 and use pin {twilioVoiceToken} followed by # or contact support.',
      },
      { twilioVoiceToken },
    );

    const cameraOnOff = COUNTRY === 'US' ? cameraOnOffUS : cameraOnOffCA;

    return clientArrived ? cameraOnOff : clientWillArriveSoon;
  };

  renderCouplesRemotes = () => {
    const { sessionType } = this.props;
    const { twilioVoiceToken, clientPresent, partnerPresent } = this.state;

    return (
      <div
        id="remote-couples"
        className="remote-couples"
        style={{ display: sessionType === 'couples' ? 'flex' : 'none' }}
      >
        <div id="remote-host-media" className="remote-couples-media">
          <GlobalLoading
            style={{ paddingBottom: '0px' }}
            message={this.renderLoadingMessage(clientPresent, twilioVoiceToken)}
          />
        </div>
        <div id="remote-guest-media" className="remote-couples-media">
          <GlobalLoading
            style={{ paddingBottom: '0px' }}
            message={this.renderLoadingMessage(
              partnerPresent,
              twilioVoiceToken,
            )}
          />
        </div>
      </div>
    );
  };

  render() {
    const { networkQualityLevel, twilioVoiceToken, clientPresent } = this.state;
    const { sessionType, intl } = this.props;
    const { formatMessage } = intl;

    return (
      <div className="video-screen-wrapper">
        <div id="remote-media-div">{this.renderCouplesRemotes()}</div>
        <div id="local-media" />
        {(sessionType !== 'couples' ||
          (sessionType === 'couples' &&
            this.state.coupleTogether === true)) && (
          <GlobalLoading
            style={{ paddingBottom: '0px' }}
            message={this.renderLoadingMessage(clientPresent, twilioVoiceToken)}
          />
        )}
        {this.state.showYouAreNowConnectedMessage && (
          <div className="you-are-now-connected-message-box">
            <p>{formatMessage({ defaultMessage: 'You are now connected' })}</p>
          </div>
        )}
        <p
          className={
            this.state.hardwareError
              ? 'unable-connect-message unable-connect-message--error'
              : 'unable-connect-message'
          }
        >
          <p>
            {formatMessage(
              {
                defaultMessage:
                  'Unable to connect? Please refresh your page <b>here</b>',
              },
              {
                b: bold => (
                  <b
                    onClick={() => window.location.reload()}
                    style={{ textDecoration: 'underline' }}
                  >
                    {bold}
                  </b>
                ),
              },
            )}
          </p>
          <span
            className="network-quality"
            style={{ color: networkColor(networkQualityLevel) }}
          >
            {networkQuality[networkQualityLevel]}
          </span>
          <span
            style={{ fontWeight: 'bold', display: 'block', marginTop: '20px' }}
          >
            {this.state.hardwareError}
          </span>
        </p>
      </div>
    );
  }
}

TwilioVideo.propTypes = {
  channel: PropTypes.string.isRequired,
  callbackForTwillio: PropTypes.func.isRequired,
  appointment: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  sessionType: PropTypes.string.isRequired,
};

TwilioVideo.defaultProps = {};

export default injectIntl(TwilioVideo);

import React, { useEffect, useState } from 'react';
import Dialog from 'components/global/mui4/Dialog';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ModuleLoadingSpinner from 'components/global/ModuleLoadingSpinner';
import FindClinic from './FindClinic';
import ClinicList from './ClinicList';
import AssociateError from './AssociateError';
import AssociateSuccess from './AssociateSuccess';
import { API_URL } from '../../../../environment';
import { headers } from '../../../../actions/defaults';
import { PAGE_STATES } from './helper';
import DestroyAlert from './DestroyAlert';
import DestroyConfirm from './DestroyConfirm';

const Index = ({
  nextPage,
  open,
  setOpen,
  clinicProfile,
  clinicYN,
  setIsClinicCounsellor,
}) => {
  const [currentPage, setCurrentPage] = useState('');
  const [searchClinicError, setSearchClinicError] = useState('');
  const [clinicName, setClinicName] = useState('');
  const [clinicToken, setClinicToken] = useState('');

  const [associateClinicError, setAssociatedClinicError] = useState(null);

  useEffect(() => {
    if (clinicProfile?.clinic_name) {
      setClinicName(clinicProfile.clinic_name);
      setClinicToken(clinicProfile.clinic_token);
    }

    if (!clinicProfile) {
      setCurrentPage(PAGE_STATES.FIND_CLINIC);
    } else if (clinicProfile && clinicYN === 'true') {
      setCurrentPage(PAGE_STATES.CLINIC_LIST);
    } else if (clinicProfile && clinicYN === 'false') {
      setCurrentPage(PAGE_STATES.DESTROY_ALERT);
    }
  }, []);

  const searchClinicHandler = async ct => {
    try {
      const res = await axios.get(
        `${API_URL}/practice/clinics/${ct}`,
        headers(),
      );
      setSearchClinicError('');
      setClinicName(res.data.clinic_name);
      setClinicToken(ct);
      setCurrentPage(PAGE_STATES.CLINIC_LIST);
    } catch (err) {
      setSearchClinicError(err.response.data.error);
    }
  };

  const associateClinicHandler = async () => {
    try {
      await axios.post(
        `${API_URL}/practice/clinic_counsellor_profile`,
        { clinic_token: clinicToken },
        headers(),
      );
      setCurrentPage(PAGE_STATES.ASSOCIATE_SUCCESS);
    } catch (err) {
      setAssociatedClinicError(err);
      setCurrentPage(PAGE_STATES.ASSOCIATE_ERROR);
    }
  };

  const destroyClinicHandler = async () => {
    try {
      axios.delete(`${API_URL}/practice/clinic_counsellor_profile`, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
        data: { clinic_token: clinicToken },
      });
      setCurrentPage(PAGE_STATES.DESTROY_CONFIRM);
    } catch (error) {
      console.error('Error deleting profile:', error);
    }
  };

  const closeHandler = () => {
    setIsClinicCounsellor(currentPage === PAGE_STATES.ASSOCIATE_SUCCESS);
    setOpen(false);
  };

  const updateCurrentPage = newState => {
    setCurrentPage(newState);
  };

  const renderContent = () => {
    switch (currentPage) {
      case PAGE_STATES.FIND_CLINIC:
        return (
          <FindClinic
            submitHandler={searchClinicHandler}
            closeHandler={closeHandler}
            error={searchClinicError}
            clearError={() => setSearchClinicError('')}
          />
        );
      case PAGE_STATES.CLINIC_LIST:
        return (
          <ClinicList
            submitHandler={associateClinicHandler}
            clinicName={clinicName}
            error={associateClinicError}
            updateCurrentPage={updateCurrentPage}
          />
        );
      case PAGE_STATES.ASSOCIATE_ERROR:
        return (
          <AssociateError
            error={associateClinicError}
            closeHandler={closeHandler}
            nextPage={nextPage}
          />
        );
      case PAGE_STATES.ASSOCIATE_SUCCESS:
        return (
          <AssociateSuccess
            clinicName={clinicName}
            nextPage={nextPage}
            closeHandler={closeHandler}
          />
        );
      case PAGE_STATES.DESTROY_ALERT:
        return (
          <DestroyAlert
            clinicName={clinicName}
            closeHandler={closeHandler}
            updateCurrentPage={updateCurrentPage}
            destroyClinicHandler={destroyClinicHandler}
          />
        );
      case PAGE_STATES.DESTROY_CONFIRM:
        return (
          <DestroyConfirm
            clinicName={clinicName}
            closeHandler={closeHandler}
            nextPage={nextPage}
          />
        );
      default:
        return (
          <div className="panel-content">
            <ModuleLoadingSpinner />
          </div>
        );
    }
  };

  return (
    <Dialog open={open}>
      <div
        className="practitioner-onboarding-layout"
        style={{
          marginTop: '0px',
          height: '50vh',
          minHeight: 'auto',
          maxWidth: '50vh',
        }}
      >
        {renderContent()}
      </div>
    </Dialog>
  );
};

Index.propTypes = {
  nextPage: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  clinicProfile: PropTypes.object,
  clinicYN: PropTypes.string.isRequired,
  setIsClinicCounsellor: PropTypes.func,
};

const mapStateToProps = state => ({
  clinicYN: state.form.PracticeOnboardingForm.values.clinic_yn,
});

export default connect(mapStateToProps)(Index);

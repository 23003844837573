import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import Input from '../form/Input';
import Checkbox from '../form/Checkbox';
import BottomBar from '../BottomBar';
import MultiSelectCheckbox from '../form/MultiSelectCheckbox';
import { updatePracticePreferences } from '../../../actions/preferences';
import { ADVANCE_REGISTRATION, logEvent } from '../../../utils/amplitude';
import { toMultiSelectOptions } from '../helpers';

class PracticeDetails3 extends Component {
  onSubmit = values => {
    const errorMessage = (
      <FormattedMessage defaultMessage="There was a problem updating your practice details." />
    );
    const params = {
      diagnosis_types: _.get(values, 'diagnosis_types', []),
      specialities: _.get(values, 'specialities', []),
      religion: {
        religions: _.get(values, 'religions', []).map(id => ({ id })),
        religion_other_yn: _.get(values, 'religion_other_yn[0]', false),
        religion_other_name: _.get(values, 'religion_other_name', ''),
      },
    };

    this.props.openLoadingPanel(
      <FormattedMessage defaultMessage="Updating practice details..." />,
    );

    this.props
      .updatePracticePreferences(params)
      .then(() => {
        logEvent(ADVANCE_REGISTRATION, {
          page: 'practice details 3',
        });
        this.props.closeLoadingPanel();
        this.props.nextPage();
      })
      .catch(error => this.props.errorCallback(error, errorMessage));
  };

  render() {
    const {
      diagnosisTypeOptions,
      specialityOptions,
      religionOptions,
      isReligionOtherVisible,
      handleSubmit,
      currentSubStep,
    } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="page-title">
          <h1>
            <FormattedMessage defaultMessage="Practice Details" />
          </h1>
          {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
          <div className="sub-page-indicator">{`${currentSubStep + 1}/7`}</div>
        </div>
        <h3>
          <FormattedMessage defaultMessage="Which topic(s) do you have a strong understanding of/experience with?" />
        </h3>
        <h4 style={{ marginTop: 40, marginBottom: 20 }}>
          <FormattedMessage defaultMessage="Diagnoses" />
        </h4>
        <div className="input-row">
          <Field
            component={MultiSelectCheckbox}
            name="diagnosis_types"
            options={diagnosisTypeOptions}
          />
        </div>
        <h4 style={{ marginTop: 10, marginBottom: 20 }}>
          <FormattedMessage defaultMessage="Specialties" />
        </h4>
        <div className="input-row">
          <Field
            component={MultiSelectCheckbox}
            name="specialities"
            options={specialityOptions}
          />
        </div>
        <h4 style={{ marginTop: 10, marginBottom: 20 }}>
          <FormattedMessage defaultMessage="Religious and spiritual knowledge" />
        </h4>
        <div className="input-row">
          <Field
            component={MultiSelectCheckbox}
            name="religions"
            options={religionOptions}
            style={{ marginBottom: -35 }}
          />
          <Field
            component={Checkbox}
            name="religion_other_yn"
            options={[
              {
                value: true,
                label: <FormattedMessage defaultMessage="Other" />,
              },
            ]}
            style={{ marginTop: 40 }}
          />
          {isReligionOtherVisible && (
            <Field
              component={Input}
              label={<span>&zwnj;</span>}
              name="religion_other_name"
            />
          )}
        </div>
        <BottomBar {...this.props} />
      </form>
    );
  }
}

PracticeDetails3.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  openLoadingPanel: PropTypes.func.isRequired,
  updatePracticePreferences: PropTypes.func.isRequired,
  closeLoadingPanel: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  errorCallback: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  diagnosisTypeOptions: PropTypes.array.isRequired,
  specialityOptions: PropTypes.array.isRequired,
  religionOptions: PropTypes.array.isRequired,
  isReligionOtherVisible: PropTypes.bool,
  currentSubStep: PropTypes.number.isRequired,
};

PracticeDetails3.defaultProps = {
  isReligionOtherVisible: false,
};

const mapStateToProps = state => {
  const selector = formValueSelector('PracticeOnboardingForm');

  return {
    diagnosisTypeOptions: toMultiSelectOptions(
      _.get(state.misc, 'diagnosisTypes', []),
    ),
    specialityOptions: toMultiSelectOptions(
      _.get(state.misc, 'specialities', []),
    ),
    religionOptions: toMultiSelectOptions(
      _.get(state.misc, 'religions', []).filter(
        religion => religion.other_yn === false,
      ),
    ),
    isReligionOtherVisible: selector(state, 'religion_other_yn[0]'),
  };
};

export default reduxForm({
  form: 'PracticeOnboardingForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(connect(mapStateToProps, { updatePracticePreferences })(PracticeDetails3));

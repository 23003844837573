import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';
import BottomBar from '../BottomBar';
import MultiSelectCheckbox from '../form/MultiSelectCheckbox';
import { updatePracticePreferences } from '../../../actions/preferences';
import { ADVANCE_REGISTRATION, logEvent } from '../../../utils/amplitude';
import { toMultiSelectOptions } from '../helpers';

class PracticeDetails4 extends Component {
  onSubmit = values => {
    const params = {
      approach_to_cares: _.get(values, 'approach_to_cares', []),
      therapeutic_orientations: _.get(values, 'therapeutic_orientations', []),
    };

    const errorMessage = (
      <FormattedMessage defaultMessage="There was a problem updating your practice details." />
    );
    this.props.openLoadingPanel(
      <FormattedMessage defaultMessage="Updating practice details..." />,
    );
    this.props
      .updatePracticePreferences(params)
      .then(() => {
        logEvent(ADVANCE_REGISTRATION, {
          page: 'practice details 4',
        });
        this.props.closeLoadingPanel();
        this.props.nextPage();
      })
      .catch(error => this.props.errorCallback(error, errorMessage));
  };

  render() {
    const {
      approachesToCareOptions,
      therapeuticOrientationOptions,
      handleSubmit,
      currentSubStep,
    } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="page-title">
          <FormattedMessage tagName="h1" defaultMessage="Practice Details" />
          {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
          <div className="sub-page-indicator">{`${currentSubStep + 1}/7`}</div>
        </div>
        <FormattedMessage
          tagName="h3"
          defaultMessage="What is your approach to care?"
        />
        <FormattedMessage
          tagName="h4"
          defaultMessage="Please choose the top three that most apply to your practice."
        />
        <div className="input-row">
          <Field
            component={MultiSelectCheckbox}
            name="approach_to_cares"
            options={approachesToCareOptions}
          />
        </div>
        <FormattedMessage
          tagName="h3"
          defaultMessage="Please select the therapeutic orientations you use in working with your clients."
        />
        <div className="input-row">
          <Field
            component={MultiSelectCheckbox}
            name="therapeutic_orientations"
            options={therapeuticOrientationOptions}
          />
        </div>
        <BottomBar {...this.props} />
      </form>
    );
  }
}

PracticeDetails4.propTypes = {
  approachesToCareOptions: PropTypes.array.isRequired,
  therapeuticOrientationOptions: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  openLoadingPanel: PropTypes.func.isRequired,
  updatePracticePreferences: PropTypes.func.isRequired,
  closeLoadingPanel: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  errorCallback: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  currentSubStep: PropTypes.number.isRequired,
};

const validate = values => {
  const errors = {};

  if (!values.approach_to_cares || values.approach_to_cares.length !== 3) {
    errors.approach_to_cares = (
      <FormattedMessage defaultMessage="You must select 3 approaches" />
    );
  }

  return errors;
};

const mapStateToProps = state => ({
  approachesToCareOptions: toMultiSelectOptions(
    _.get(state.misc, 'approachesToCare', []),
  ),
  therapeuticOrientationOptions: toMultiSelectOptions(
    _.get(state.misc, 'therapeuticOrientations', []),
  ),
});

export default reduxForm({
  form: 'PracticeOnboardingForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(connect(mapStateToProps, { updatePracticePreferences })(PracticeDetails4));

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Dialog as DialogV4, DialogContent } from '@material-ui/core';

const useStyles = makeStyles({
  content: {
    padding: '30px',
  },
});

const Dialog = props => {
  const classes = useStyles(props);

  return (
    <DialogV4 open={props.open} onClose={props.onClose}>
      <DialogContent className={classes.content}>
        {props.children}
      </DialogContent>
    </DialogV4>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.object.isRequired,
};

export default Dialog;

/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import map from 'lodash/map';
import PreAuthTopBar from 'components/global/PreAuthTopBar';
// eslint-disable-next-line import/no-cycle
import AutomaticPayouts from './pages/AutomaticPayouts';
import Panel from './Panel';
import PersonalDetails from './pages/PersonalDetails';
import ThankYou from './pages/ThankYou';
import { fetchUser } from '../../actions/user';
import LoadingPanel from '../global/LoadingPanel';
import PracticeDetails from '../onboarding/pages/PracticeDetails0';
import { scrollToTop } from './scroll';
import TaxInformation from '../onboarding/pages/TaxInformation';
import { COUNTRY } from '../../environment';
import ErrorDialog from './ErrorDialog';
import {
  PERSONAL_DETAILS,
  PRACTICE_DETAILS,
  LOCATION_DETAILS,
  MATCHING_PREFERENCES,
  AUTOMATIC_PAYOUTS,
  THANK_YOU,
  TAX_INFORMATION,
} from './data';
import { signoutUser } from '../../actions/auths';
import LocationDetails from './pages/LocationDetails';
import MatchingPreferences from './pages/MatchingPreferences';
import BottomBar from './BottomBar';

class PracticeOnboarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClinicCounsellor: false,
      page: PERSONAL_DETAILS,
      subPage: 0,
      loadingPanel: {
        open: false,
        message: '',
      },
      error: {
        open: false,
        message: '',
      },
    };
  }

  componentDidMount() {
    if (this.props.authenticated) {
      this.redirect();
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    const { subPage, page } = this.state;

    if (subPage !== prevState.subPage || page !== prevState.page) {
      scrollToTop();
    }
  }

  setSubPage = (subPage = 0) => this.setState({ subPage });

  setPage = page => this.setState({ page });

  nextSubPage = () =>
    this.setState(prevState => ({ subPage: prevState.subPage + 1 }));

  previousSubPage = () =>
    this.setState(prevState => ({ subPage: prevState.subPage - 1 }));

  nextPage = () => this.setState(prevState => ({ page: prevState.page + 1 }));

  previousPage = () =>
    this.setState(prevState => ({ page: prevState.page - 1 }));

  openErrorDialog = message =>
    this.setState({ error: { open: true, message } });

  closeErrorDialog = () =>
    this.setState({ error: { open: false, message: '' } });

  openLoadingPanel = message => {
    scrollToTop();
    this.setState({ loadingPanel: { open: true, message } });
  };

  closeLoadingPanel = () =>
    this.setState({ loadingPanel: { open: false, message: '' } });

  skipToEnd = () => {
    this.setPage(THANK_YOU);
  };

  skipToNext = currentStep => this.setPage(currentStep + 1);

  sendToDashboard = () => {
    this.props.fetchUser(() => this.props.history.push('/dashboard'));
  };

  errorCallback = (_error, message) => {
    this.closeLoadingPanel();
    this.openErrorDialog(message);
  };

  redirect = () => {
    this.openLoadingPanel(
      this.props.intl.formatMessage({ defaultMessage: 'Loading...' }),
    );
    this.props.fetchUser(
      response => {
        this.closeLoadingPanel();
        if (response.data) {
          this.setState({ page: PRACTICE_DETAILS });
        }
      },
      () => {
        this.props.signoutUser();
        this.props.history.push('/signin');
      },
    );
  };

  setIsClinicCounsellor = value => {
    this.setState({
      isClinicCounsellor: value,
    });
  };

  render() {
    const { page, loadingPanel, error, isClinicCounsellor } = this.state;
    const pageProps = {
      currentStep: page,
      nextPage: this.nextPage,
      previousPage: this.previousPage,
      openErrorDialog: this.openErrorDialog,
      openLoadingPanel: this.openLoadingPanel,
      closeLoadingPanel: this.closeLoadingPanel,
      errorCallback: this.errorCallback,
      skipToEnd: this.skipToEnd,
      skipToNext: this.skipToNext,
    };

    return (
      <>
        <PreAuthTopBar locale={localStorage.getItem('locale')} />
        <div className="practitioner-onboarding-layout">
          <div className="light-background" />
          <div className="dark-background" />
          <Panel isClinicCounsellor={isClinicCounsellor} currentStep={page}>
            {page === PERSONAL_DETAILS && <PersonalDetails {...pageProps} />}
            {page === PRACTICE_DETAILS && COUNTRY === 'CA' && (
              <PracticeDetails
                isClinicCounsellor={isClinicCounsellor}
                setIsClinicCounsellor={this.setIsClinicCounsellor}
                bottomBar={BottomBar}
                skipToEnd={this.skipToEnd}
                {...pageProps}
              />
            )}
            {page === LOCATION_DETAILS && <LocationDetails {...pageProps} />}
            {page === MATCHING_PREFERENCES && (
              <MatchingPreferences
                isClinicCounsellor={isClinicCounsellor}
                setPage={this.setPage}
                {...pageProps}
              />
            )}
            {page === TAX_INFORMATION && COUNTRY === 'CA' && (
              <TaxInformation {...pageProps} />
            )}
            {page === AUTOMATIC_PAYOUTS && <AutomaticPayouts {...pageProps} />}
            {page === THANK_YOU && (
              <ThankYou
                currentStep={page}
                nextPage={this.sendToDashboard}
                nonFormPage
              />
            )}
            {loadingPanel.open && (
              <LoadingPanel message={loadingPanel.message} />
            )}
            <ErrorDialog
              open={error.open}
              handleClose={this.closeErrorDialog}
              message={error.message}
            />
          </Panel>
        </div>
      </>
    );
  }
}

PracticeOnboarding.propTypes = {
  ageGroups: PropTypes.array,
  authenticated: PropTypes.bool,
  designations: PropTypes.array,
  disorders: PropTypes.array,
  history: PropTypes.object.isRequired,
  languages: PropTypes.array,
  fetchUser: PropTypes.func.isRequired,
  insuranceCompanies: PropTypes.array,
  approachesToCare: PropTypes.array,
  signoutUser: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

PracticeOnboarding.defaultProps = {
  ageGroups: [],
  authenticated: false,
  designations: [],
  disorders: [],
  languages: [],
  insuranceCompanies: [],
  approachesToCare: [],
};

const mapStateToProps = state => ({
  ageGroups: state.misc.ageGroups,
  authenticated: state.auth.authenticated,
  designations: map(state.misc.designations, designation => ({
    value: designation.acronym,
    label: designation.name,
  })),
  disorders: state.misc.disorders,
  languages: state.misc.languages,
  insuranceCompanies: state.misc.insuranceCompanies,
  approachesToCare: map(state.misc.approachesToCare, approach => ({
    value: approach.locale_key,
    label: approach.name,
  })),
});

export default injectIntl(
  connect(mapStateToProps, {
    signoutUser,
    fetchUser,
  })(PracticeOnboarding),
);

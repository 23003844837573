import React from 'react';
import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { yellow } from '@material-ui/core/colors';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const DestroyAlert = ({ closeHandler, clinicName, destroyClinicHandler }) => {
  const handleSubmit = () => {
    destroyClinicHandler();
  };

  return (
    <div className="panel-content">
      <div style={{ marginBottom: '20px' }}>
        <ErrorOutlineIcon style={{ color: yellow[800], fontSize: 48 }} />
        <h3>
          <FormattedMessage defaultMessage="Register as an individual practitioner?" />
        </h3>
        <h6>
          <FormattedMessage
            defaultMessage="You are currently associated with {clinicName}! You will be removed from the clinic."
            values={{ clinicName }}
          />
        </h6>
      </div>
      <div>
        <div className="bottom-bar">
          <div className="flex-left">
            <Button className="rounded-button-white" onClick={closeHandler}>
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
            <Button
              className="rounded-button-teal next-button"
              type="button"
              onClick={handleSubmit}
            >
              <div>
                <span style={{ color: '#fff' }}>
                  <FormattedMessage defaultMessage="Confirm" />
                </span>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
DestroyAlert.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  clinicName: PropTypes.string.isRequired,
  destroyClinicHandler: PropTypes.func.isRequired,
};

export default DestroyAlert;

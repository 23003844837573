/* eslint-disable react/jsx-no-literals */
import React from 'react';

function localeMessage(id, locale = 'en') {
  const found = messages.find(message => message.id === id);

  if (!found) {
    return '';
  }

  return found[locale];
}

const messages = [
  {
    id: 'tellUsMore',
    en: 'Tell us more about your experience',
    fr: 'Parlez-nous de votre expérience',
  },
  {
    id: 'yourCare',
    en: (
      <div>
        The person you are waiting for will arrive soon. If you don&apos;t see
        them and it is after the scheduled appointment start time, message them
        directly in the chat box. For more information,
        <a href="https://inkblot.zendesk.com/hc/en-us/articles/360053062011-Troubleshooting-Cannot-connect-to-my-appointment">
          visit the Help Centre
        </a>
      </div>
    ),
    fr: (
      <div>
        La personne que vous attendez arrivera bientôt. Si vous ne la voyez pas
        et l&apos;heure de début de votre séance est dépassée, envoyez-lui un
        message directement dans la boîte de dialogue. Pour plus
        d&apos;informations,
        <a href="https://inkblot.zendesk.com/hc/fr-ca/articles/360053062011-Troubleshooting-Cannot-connect-to-my-appointment">
          visitez le Centre d&apos;aide
        </a>
      </div>
    ),
  },
  {
    id: 'yourPartner',
    en: 'Your partner will arrive soon.',
    fr: 'Votre partenaire va bientôt arriver.',
  },
  {
    id: 'yourPartnerIssue',
    en:
      'It appears that your partner either has their camera or microphone off, or is having some hardware issues. ',
    fr:
      'Il semble que la caméra ou le microphone de votre partenaire soit désactivé, ou qu’il éprouve des problèmes techniques.',
  },
  {
    id: 'yourProviderIssue',
    en:
      'It appears that your care provider has their camera or microphone off, or may be having some hardware issues. ',
    fr:
      'Il semble que la caméra ou le microphone de votre prestataire de soins soit désactivé, ou qu’il éprouve des problèmes techniques.',
  },
  {
    id: 'uniqueDialInCode',
    en: 'You can choose to use your unique dial in code to connect by voice: ',
    fr:
      'Vous pouvez choisir d’utiliser votre code d’accès unique pour vous connecter par téléphone : ',
  },
  {
    id: 'contactSupport',
    en: ' or contact support.',
    fr: ' ou de communiquer avec le soutien.',
  },
  {
    id: 'unableTo',
    en: 'Unable to connect? Please refresh your page ',
    fr: 'Vous avez des difficultés à vous connecter? ',
  },
  {
    id: 'here',
    en: 'here',
    fr: 'Cliquez ici',
  },
  {
    id: 'Chat',
    en: 'Chat',
    fr: 'Clavardage',
  },
  {
    id: 'Notes',
    en: 'Notes',
    fr: 'Notes',
  },
  {
    id: 'Audio Difficulties',
    en: 'Audio Difficulties',
    fr: 'Difficultés avec l’audio',
  },
  {
    id: 'forAudio',
    en: 'For audio difficulties, please call ',
    fr: 'En cas de difficulté avec l’audio, veuillez contacter ',
  },
  {
    id: 'withPin',
    en: ' with pin ',
    fr: ' avec le NIP ',
  },
  {
    id: 'completeSession',
    en: 'Complete Session',
    fr: 'Terminez la session',
  },
  {
    id: 'reconnect',
    en: 'Reconnect',
    fr: 'Se connecter à nouveau',
  },
  {
    id: 'hideTime',
    en: 'Hide Time',
    fr: 'Masquez l’heure',
  },
  {
    id: 'showTime',
    en: 'Show time',
    fr: 'Afficher le temps',
  },
  {
    id: 'careProvider',
    en: 'Care Provider',
    fr: 'Conseiller',
  },
  {
    id: 'partner',
    en: 'Partner',
    fr: 'Partenaire',
  },
  {
    id: 'hasExpired',
    en: 'This session has expired',
    fr: 'Cette session est expirée',
  },
  {
    id: 'typeMessage',
    en: 'Type a message',
    fr: 'Veuillez taper un message',
  },
  {
    id: 'rateSession',
    en: 'How would you rate the session?',
    fr: 'Comment évaluez-vous la session?',
  },
  {
    id: 'successful',
    en: 'Successful: ',
    fr: 'Avec succès : ',
  },
  {
    id: 'noIssues',
    en: 'my session had no issues',
    fr: 'sans difficulté',
  },
  {
    id: 'unsuccessful',
    en: 'Unsuccessful: ',
    fr: 'Infructueuse : ',
  },
  {
    id: 'chooseReason',
    en: 'please choose a reason why',
    fr: 'sélectionnez une raison',
  },
  {
    id: 'technicalIssues',
    en: 'Technical issues',
    fr: 'Difficultés techniques',
  },
  {
    id: 'forMe',
    en: 'Counselling isn’t for me',
    fr: 'Le counselling n’est pas pour moi',
  },
  {
    id: 'notConnect',
    en: 'I didn’t connect with my care provider',
    fr: 'Ça n’a pas cliqué avec mon conseiller',
  },
  {
    id: 'notShow',
    en: 'My care provider was very late/didn’t show up',
    fr: 'Mon conseiller était en retard / ne s’est pas présenté',
  },
  {
    id: 'wasUnprofessional',
    en: 'My care provider was unprofessional',
    fr: 'Mon conseiller était peu professionnel',
  },
  {
    id: 'other',
    en: 'Other',
    fr: 'Autre',
  },
  {
    id: 'toComplete',
    en: 'Are you sure you want to complete the session?',
    fr: 'Êtes-vous certain de vouloir terminer la session?',
  },
  {
    id: 'yes',
    en: 'Yes',
    fr: 'Oui',
  },
  {
    id: 'no',
    en: 'No',
    fr: 'Non',
  },
  {
    id: 'partnerRoom',
    en: 'Are you and your partner in the same room?',
    fr: 'Est-ce vous et votre partenaire êtes dans la même pièce?',
  },
  {
    id: 'sameRoom',
    en: 'Yes, We are in the same room.',
    fr: 'Oui, nous sommes dans la même pièce.',
  },
  {
    id: 'joiningSeparately',
    en: 'No, We are joining separately.',
    fr: 'Non, nous joignons de façon séparée.',
  },
  {
    id: 'NotAllowedError',
    en:
      'Your browser is not permitting access to your camera/microphone, please refresh after fixing.',
    fr:
      'Votre navigateur n’autorise pas l’accès à votre caméra/microphone, veuillez actualiser après avoir apporté les modifications nécessaires.',
  },
  {
    id: 'NotFoundError',
    en: 'Unable to detect a camera/microphone, please refresh after fixing.',
    fr:
      'Impossible de détecter une caméra/un microphone, veuillez actualiser après avoir apporté les modifications nécessaires.',
  },
  {
    id: 'NotReadableError',
    en:
      'A hardware issue has occurred with your camera/microphone, please refresh after fixing.',
    fr:
      'Un problème matériel s’est produit avec votre caméra/microphone, veuillez actualiser après avoir apporté les modifications nécessaires.',
  },
  {
    id: 'UnknownError',
    en: 'Unknown Error',
    fr: 'Erreur inconnue',
  },
  {
    id: 'youAreNowConnected',
    en: 'You are now connected',
    fr: 'Vous êtes maintenant connecté.',
  },
  {
    id: 'successfulSession',
    en: 'Successful: my session had no issues',
    fr: "Succès : ma séance s'est déroulée sans problème.",
  },
  {
    id: 'requiredError',
    en: 'Required',
    fr: 'Obligatoire',
  },
];

export default localeMessage;

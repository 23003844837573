import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import Drawer from 'material-ui/Drawer';
import { Select, MenuItem, Grid } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import FlatButton from 'material-ui/FlatButton';
import Snackbar from 'material-ui/Snackbar';
import NavigationClose from '@material-ui/icons/Close';

import GlobalTitle from '../global/Title';
import ClientSelect from '../global/ClientSelect';
import ConfirmationDialog from '../global/ConfirmationDialog';
import TopBar from './partials/topBar';
import GlobalPanel from '../global/Panel';
import DataLoadingSpinner from '../global/DataLoadingSpinner';
import SendFiles from './SendFiles';
import { fetchPaginatedSharedWith, removeFile } from '../../actions/resources';

const propTypes = {
  fetchPaginatedSharedWith: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

class FilesToClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSendingFile: false,
      showSnack: false,
      snackMessage: '',
      openDeleteConfirm: false,
      files: [],
      pagination: {},
      clientId: null,
      sortBy: 'date-newest',
      deletingFileId: null,
      loading: false,
    };
  }

  componentDidMount = () => {
    this.fetchList(1);
  };

  fetchList = page => {
    const { clientId, sortBy } = this.state;
    this.setState({ loading: true });
    this.props.fetchPaginatedSharedWith(
      page,
      clientId,
      sortBy,
      (files, error) => {
        this.setState({ loading: false });
        if (!error) {
          this.setState({
            files: files.data,
            pagination: files.pagination_info,
          });
        }
      },
    );
  };

  showSnack = message =>
    this.setState({ showSnack: true, snackMessage: message });

  hideSnack = () => this.setState({ showSnack: false });

  showSendingFile = () => this.setState({ openSendingFile: true });

  hideSendingFile = () =>
    this.setState({ openSendingFile: false }, () => this.fetchList());

  showDeleteConfirm = deletingFileId =>
    this.setState({ deletingFileId, openDeleteConfirm: true });

  hideDeleteConfirm = () => this.setState({ openDeleteConfirm: false });

  handleClientSelectChange = clientId => {
    this.setState({ clientId }, () => this.fetchList(1));
  };

  handleSortChange = event => {
    this.setState({ sortBy: event.target.value }, () => this.fetchList(1));
  };

  deleteFile = id => {
    this.props.removeFile(id, () => {
      this.showSnack(
        <FormattedMessage defaultMessage="Successfully deleted file." />,
      );
      this.fetchList(1);
    });
  };

  renderRows() {
    const { files } = this.state;
    const { formatDate } = this.props.intl;

    return files.map(file => (
      <TableRow key={file.id}>
        <TableRowColumn>
          {formatDate(file.created_at, {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: localStorage.getItem('current-timezone'),
          })}
        </TableRowColumn>
        <TableRowColumn className="process-name">
          {file.shared_user}
        </TableRowColumn>
        <TableRowColumn>
          {file.file_name.replace(/__([a-zA-Z0-9])+\./, '.')}
        </TableRowColumn>
        <TableRowColumn>
          <div style={{ display: 'flex' }}>
            <FlatButton
              href={file.resource}
              className="flat-button-baby-blue process-action-button pab-small"
              label={<FormattedMessage defaultMessage="View" />}
              download
              target="_blank"
            />
            &nbsp;
            <FlatButton
              onClick={() => this.showDeleteConfirm(file.id)}
              className="flat-button-red process-action-button pab-small"
              label={<FormattedMessage defaultMessage="Delete" />}
            />
          </div>
        </TableRowColumn>
      </TableRow>
    ));
  }

  renderTable = () => {
    const { pagination } = this.state;
    return (
      <div className="module-table">
        <Table
          style={{ marginLeft: 0 }}
          className="appointment-table"
          selectable={false}
        >
          <TableHeader
            className="remove-border"
            displaySelectAll={false}
            adjustForCheckbox={false}
          >
            <TableRow className="remove-border" selectable={false}>
              <TableHeaderColumn>
                <FormattedMessage defaultMessage="Date" />
              </TableHeaderColumn>
              <TableHeaderColumn>
                <FormattedMessage defaultMessage="Client" />
              </TableHeaderColumn>
              <TableHeaderColumn>
                <FormattedMessage defaultMessage="File" />
              </TableHeaderColumn>
              <TableHeaderColumn>
                <FormattedMessage defaultMessage="Action" />
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>{this.renderRows()}</TableBody>
        </Table>
        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}
        >
          {pagination && pagination.total && pagination.total_items ? (
            <Pagination
              shape="rounded"
              count={pagination.total}
              page={pagination.current}
              onChange={(_, page) => this.fetchList(page)}
            />
          ) : (
            <noscript />
          )}
        </div>
      </div>
    );
  };

  render() {
    const {
      showSnack,
      snackMessage,
      openDeleteConfirm,
      deletingFileId,
      loading,
    } = this.state;
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <TopBar activeTab="files_to_clients" />
        <Drawer
          open={this.state.openSendingFile}
          openSecondary
          width="40%"
          docked={false}
          onRequestChange={this.hideSendingFile}
        >
          <div style={{ marginLeft: '40px', marginTop: '32px' }}>
            <GlobalTitle
              title={<FormattedMessage defaultMessage="Send Files" />}
            >
              <NavigationClose
                onClick={this.hideSendingFile}
                className="closeButtonTopRight"
                style={{ marginRight: '35px' }}
              />
            </GlobalTitle>
            {this.state.openSendingFile ? (
              <SendFiles
                closeModal={this.hideSendingFile}
                openSnack={this.showSnack}
                toAll
              />
            ) : (
              <div />
            )}
          </div>
        </Drawer>
        <GlobalPanel>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
              marginBottom: 30,
            }}
          >
            <Grid container spacing={2}>
              <Grid item>
                <div style={{ width: 250 }}>
                  <ClientSelect onChange={this.handleClientSelectChange} />
                </div>
              </Grid>
              <Grid item>
                <Select
                  value={this.state.sortBy}
                  onChange={this.handleSortChange}
                >
                  <MenuItem value="date-newest">
                    {formatMessage({
                      defaultMessage: 'Date - Newest First',
                    })}
                  </MenuItem>
                  <MenuItem value="date-oldest">
                    {formatMessage({
                      defaultMessage: 'Date - Oldest First',
                    })}
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
            <FlatButton
              className="flat-button-green"
              label={<FormattedMessage defaultMessage="SEND NEW FILE" />}
              onClick={this.showSendingFile}
            />
          </div>
          {loading ? <DataLoadingSpinner /> : this.renderTable()}
        </GlobalPanel>
        {openDeleteConfirm && (
          <ConfirmationDialog
            open={openDeleteConfirm}
            message={formatMessage({
              defaultMessage: 'Are you sure you want to delete this file?',
            })}
            handleConfirm={() => this.deleteFile(deletingFileId)}
            handleClose={this.hideDeleteConfirm}
          />
        )}
        <Snackbar
          open={showSnack}
          message={snackMessage}
          autoHideDuration={4000}
          onRequestClose={this.hideSnack}
        />
      </div>
    );
  }
}

FilesToClients.propTypes = propTypes;

export default injectIntl(
  connect(null, { fetchPaginatedSharedWith, removeFile })(FilesToClients),
);

import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export const validatePhone = value =>
  value && !/^\d{3}-\d{3}-\d{4}$/i.test(value) ? (
    <FormattedMessage defaultMessage="Invalid phone number" />
  ) : (
    undefined
  );

export const required = value =>
  value ? undefined : <FormattedMessage defaultMessage="Required" />;

export const email = value =>
  value && !/^[À-ÿA-Z0-9._%+-]+@[À-ÿA-Z0-9.-]+\.[A-Z]{2,6}$/i.test(value) ? (
    <FormattedMessage defaultMessage="Please enter a valid email" />
  ) : (
    undefined
  );

export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const maxLenth = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const passwordLength = value =>
  value && value.length < 8 ? (
    <FormattedMessage defaultMessage="Password must be at least 8 characters" />
  ) : (
    undefined
  );

export const canadianPostal = value =>
  value &&
  !/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i.test(
    value,
  ) ? (
    <FormattedMessage defaultMessage="Invalid Postal code" />
  ) : (
    undefined
  );

export const usPostal = value =>
  value && !/^[0-9]{5}(?:-[0-9]{4})?$/i.test(value) ? (
    <FormattedMessage defaultMessage="Invalid Zip code" />
  ) : (
    undefined
  );

export const normalizeCanadianPostal = value => {
  if (!value) {
    return value;
  }

  const noSpace = value.replace(' ', '');
  const onlyUpperCase = noSpace.replace(/[a-z]/g, str => str.toUpperCase());

  if (onlyUpperCase.length <= 3) {
    return onlyUpperCase;
  }

  return `${onlyUpperCase.slice(0, 3)} ${onlyUpperCase.slice(3, 6)}`;
};

export const normalizeUSZip = value => {
  if (!value) {
    return value;
  }

  const hasLastDash = value.match(/-$/);
  const onlyDigit = value.replace(/[^\d+]/, '');

  if (onlyDigit.length <= 4) {
    return onlyDigit;
  }

  if (onlyDigit.length === 5) {
    if (hasLastDash) {
      return `${onlyDigit.slice(0, 5)}-`;
    }
    return onlyDigit;
  }

  if (onlyDigit.length === 5 && hasLastDash) {
    return `${onlyDigit.slice(0, 5)}-`;
  }

  return `${onlyDigit.slice(0, 5)}-${onlyDigit.slice(5, 9)}`;
};

export const validateNumeric = value =>
  value && !/^([+-]?[1-9]\d*|0)$/i.test(value)
    ? 'Please enter a numeric value'
    : undefined;

export const validateZeroNumeric = value =>
  value && !/^\d+$/i.test(value) ? (
    <FormattedMessage defaultMessage="Please enter a numeric value" />
  ) : (
    undefined
  );

export const validateCurrency = value =>
  value && !/^\d+(\.\d{1,2})?$/.test(value)
    ? 'Please Enter a Currency Value (xxx.xx)'
    : undefined;

export const validateHour = value =>
  value && !/^\d+(\.\d{1,2})?$/.test(value) ? (
    <FormattedMessage defaultMessage="Please enter a valid value (x.xx)" />
  ) : (
    undefined
  );

export const digitCount = count => value => {
  const regex = new RegExp(`^\\d{${count}}$`);
  return value && !regex.test(value) ? (
    <FormattedMessage
      defaultMessage="Please Enter a {count} Digit Numeric Value"
      values={{ count }}
    />
  ) : (
    undefined
  );
};

export const validateZeroNumericDigit3 = digitCount(3);

export const validateZeroNumericDigit5 = digitCount(5);

export const validateZeroNumericDigit9 = digitCount(9);

export const validateDate = value =>
  value &&
  !/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i.test(
    value,
  )
    ? 'Invalid Date Format'
    : undefined;

export const validateAge = value => {
  const eighteen = moment().subtract(18, 'years');
  return eighteen.isAfter(moment(value, 'DD/MM/YYYY'))
    ? undefined
    : 'Employee is less than 18 years old.';
};

export const matchingPassword = value =>
  value === document.getElementById('password').value ? (
    undefined
  ) : (
    <FormattedMessage defaultMessage="Passwords do not match" />
  );

export const matchingEmails = value =>
  value === document.getElementById('email').value
    ? undefined
    : 'Your email does not match';

// Validates that the input string is a valid date formatted as "mm/dd/yyyy"
export const isValidBirthdate = dateString => {
  const errorMessage = <FormattedMessage defaultMessage="Not a valid date" />;
  if (!dateString) return;
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return errorMessage;

  // Parse the date parts to integers
  const parts = dateString.split('/');
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[0], 10);
  const year = parseInt(parts[2], 10);

  const dobDate = new Date(year, month - 1, day);
  const beginningToday = new Date(new Date().setHours(0, 0, 0, 0));

  if (dobDate >= beginningToday) {
    return errorMessage;
  }

  // Check the ranges of month and year
  if (
    year < 1880 ||
    year > new Date().getFullYear() ||
    month === 0 ||
    month > 12
  ) {
    return errorMessage;
  }

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  // Check the range of the day
  if (!(day > 0 && day <= monthLength[month - 1])) return errorMessage;

  return undefined;
};

export const validPercent = value => {
  const errorMessage = 'Please enter a valid percent';
  if (Number.isNaN(value)) return errorMessage;

  const percent = parseFloat(value);
  if (percent < 0 || percent > 100) return errorMessage;

  return undefined;
};

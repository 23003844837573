import React from 'react';
import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { yellow } from '@material-ui/core/colors';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'; // Import withRouter

const ErrorClinic = ({ error, closeHandler }) => {
  const buttonHandler = () => {
    closeHandler();
  };

  return (
    <div className="panel-content">
      <div style={{ marginBottom: '20px' }}>
        <ErrorOutlineIcon style={{ color: yellow[800], fontSize: 48 }} />
        <br />
        <h2>
          <FormattedMessage defaultMessage="Something went wrong" />
        </h2>
        <p>{error.response.data.error}</p>
      </div>
      <div className="bottom-bar">
        <div className="flex-left">
          <Button
            className="rounded-button-teal"
            type="button"
            onClick={buttonHandler}
          >
            <div>
              <span>
                <FormattedMessage defaultMessage="Okay, got it" />
              </span>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};
ErrorClinic.propTypes = {
  error: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
};

export default withRouter(ErrorClinic);

import React, { Component } from 'react';
import { Client } from '@twilio/conversations';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Close from '@material-ui/icons/Close';
import ExpandLess from '@material-ui/icons/ExpandLess';
import {
  fetchTwilioTokenWithChatId,
  sendMessage,
  markAsRead,
} from '../../../../actions/messaging';
import MessageList from './MessageList';
import MessageForm from './MessageForm';

class TextChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      channel: null,
      chat: null,
    };
  }

  componentDidMount = () => {
    this.initChats();
  };

  // Set up channel after it has been found
  setupChannel = channel => {
    // Get Messages for a previously created channel
    channel.getMessages().then(messages => {
      const totalMessages = messages.items.length;
      for (let message = 0; message < totalMessages; message += 1) {
        this.addMessage(messages.items[message].state, false);
      }
    });

    channel.on('messageAdded', ({ author, body, dateCreated }) => {
      this.addMessage({ author, body, timestamp: dateCreated }, true);
    });
  };

  initChats = () => {
    const {
      appointment: { chat },
    } = this.props;
    const chatId = chat.id;

    this.props.fetchTwilioTokenWithChatId(chatId, tokenResponse => {
      const token = tokenResponse.data;
      const chatClient = new Client(token.id);

      chatClient.on('stateChanged', state => {
        if (state === 'initialized') {
          this.rejoinChannel(chatClient);
        }
      });
    });
  };

  rejoinChannel = chatClient => {
    const {
      appointment: { chat },
    } = this.props;

    this.setState({ chat }, () => this.props.markAsRead({ id: chat.id }));
    const promise = chatClient.getConversationBySid(chat.channel);
    promise
      .then(channel => {
        this.setState({ channel });
        this.setupChannel(channel);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  };

  addMessage = (message, mark = false) => {
    const messageData = {
      ...message,
      me: message.author === this.props.token.identity,
    };
    this.setState(
      {
        // eslint-disable-next-line react/no-access-state-in-setstate
        messages: [...this.state.messages, messageData],
      },
      () => {
        if (!messageData.me && mark) {
          this.props.markAsRead({ id: this.state.chat.id });
        }
      },
    );
    if (!this.props.open) {
      this.props.toggle();
    }
  };

  handleNewMessage = text => {
    if (this.state.channel) {
      this.props.sendMessage({
        id: this.state.chat.id,
        message: text,
        sensitive_yn: true,
      });
      this.state.channel.sendMessage(text);
    }
  };

  render() {
    const { messages } = this.state;
    const { toggle, open, timezone, receivers } = this.props;

    return (
      <div className="chat-container">
        {open ? (
          <Close
            onClick={toggle}
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              cursor: 'pointer',
              color: 'white',
              width: '13px',
              height: '13px',
            }}
          />
        ) : (
          <ExpandLess
            onClick={toggle}
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              cursor: 'pointer',
              color: 'white',
              width: '16px',
              height: '16px',
            }}
          />
        )}
        <div className="chat-title">
          <p className="chat-title-text">
            {receivers.map(receiver => receiver.preferred_name_with_last_name).join(', ')}
          </p>
        </div>
        <div className={open ? '' : 'close-message-box'}>
          <MessageList messages={messages} timezone={timezone} />
          <MessageForm
            connected_yn={this.state.channel !== null}
            onMessageSend={this.handleNewMessage}
          />
        </div>
      </div>
    );
  }
}

TextChat.propTypes = {
  fetchTwilioTokenWithChatId: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  token: PropTypes.object,
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  receivers: PropTypes.array.isRequired,
  appointment: PropTypes.object,
  markAsRead: PropTypes.func,
};

TextChat.defaultProps = {
  token: {},
  appointment: {},
};

function mapStateToProps(state) {
  return {
    token: state.misc.token,
  };
}

export default connect(mapStateToProps, {
  fetchTwilioTokenWithChatId,
  sendMessage,
  markAsRead,
})(TextChat);

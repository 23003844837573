/* eslint-disable import/no-cycle */
/* eslint no-console: 0 */
import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Fullscreen from 'react-full-screen';
import { Snackbar } from 'material-ui';
import * as utils from './utils';
import VideoBar from './VideoBar';
import Panel from '../panel';
import InfoModal from './InfoModal';
import {
  fetchAppointment,
  fetchAppointmentNotes,
  fetchSessionAnalytics,
  updateAppointmentSession,
} from '../../../actions/sessions';
import { fetchClient } from '../../../actions/clients';
import { fetchUser } from '../../../actions/user';
import { updateAppointment } from '../../../ducks/sessions/appointment';
import { fetchTodayAppointments } from '../../../ducks/dashboard/todayAppointments';
import TextChat from './text_chat/TextChat';
import TwilioAudio from '../panel/TwilioAudio';
import { fetchDocuments } from '../../../actions/process';
import LoadingPanel from '../../global/LoadingPanel';
import TwilioVideo from './TwilioVideo';
import CameraIMG from './camera.png';
import MicrophoneIMG from './microphone.png';
import CameraOffIMG from './cameraOff.png';
import MicrophoneOffIMG from './microphoneOff.png';
import ReloadIMG from './reload.png';

class VidyoClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countdown: '',
      showInfo: false,
      snackbarOpen: false,
      isFull: false,
      showChatBox: true,
      joined: false,
      cameraOpen: true,
      audioOpen: true,
      isStarted: false,
    };

    this.twilioControl = {
      toggleCamera: () => {},
      toggleMic: () => {},
    };
  }

  componentDidMount() {
    const { match } = this.props;

    this.props.fetchAppointment(match.params.id, apptResponse => {
      if (apptResponse.data.status === 'scheduled') {
        this.props.updateAppointment(match.params.id, { status: 'pending' });
      }

      const clientID = apptResponse.data.client.id;
      const now = moment(new Date());
      const end = moment.utc(apptResponse.data.end_date);

      const ms = moment(end, 'DD/MM/YYYY HH:mm:ss').diff(
        moment(now, 'DD/MM/YYYY HH:mm:ss'),
      );

      this.countdownTimer(ms);
      this.props.fetchUser(() => {
        const { me } = this.props;

        // appointment_id belonging to the current user
        const appointmentSessionId = _.find(
          apptResponse.data.appointment_sessions,
          {
            user_id: me && me.id,
          },
        ).id;

        // lets us know the user joined
        this.props.updateAppointmentSession(appointmentSessionId, {
          joined_yn: true,
          source: 'web',
        });

        this.props.fetchClient(clientID);
        this.props.fetchSessionAnalytics(clientID);
        this.props.fetchAppointmentNotes(clientID);
      });
    });
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  // disconnects the vidyo, sets session as completed, and pushes them
  disconnectAll = () => {
    clearInterval(this.intervalID);
    utils.pushThem(this);
  };

  // countdown the time remaining in a session
  countdownTimer = time => {
    let newTime = time;
    const interval = 1000;
    const oneHour = 3600000;
    const endTime = moment(this.props.appointment.end_date);

    this.intervalID = setInterval(() => {
      newTime -= interval;
      const d = moment.duration(newTime);
      const s = Math.floor(d.asHours()) + moment.utc(newTime).format(':mm');

      this.setState(() => ({
        countdownTime: newTime,
        countdown: s,
      }));

      // When the session has expired, user can still remain in the session
      if (newTime < 0) {
        const timeOver = moment().diff(endTime, 'minutes');
        const negativeRemainder =
          timeOver >= 10 ? `-0:${timeOver}` : `-0:0${timeOver}`;
        this.setState(() => ({
          snackbarOpen: true,
          countdown: negativeRemainder,
        }));
      }

      // Kick users out if they remain after the session has been expired for an hour
      if (newTime < -oneHour) {
        clearInterval(this.intervalID);
        this.disconnectAll();
      }
    }, interval);
  };

  toggle = modal => {
    this.setState(prevState => ({
      [modal]: !prevState[modal],
    }));
  };

  toggleChatBox = () => {
    this.setState(prevState => ({
      showChatBox: !prevState.showChatBox,
    }));
  };

  toggleCamera = () => {
    this.twilioControl.toggleCamera();
    this.setState(prevState => ({ cameraOpen: !prevState.cameraOpen }));
  };

  toggleMic = () => {
    this.twilioControl.toggleMic();
    this.setState(prevState => ({ audioOpen: !prevState.audioOpen }));
  };

  callbackForTwillio = controls => {
    if (controls.toggleCamera) {
      this.twilioControl.toggleCamera = controls.toggleCamera;
    }

    if (controls.toggleMic) {
      this.twilioControl.toggleMic = controls.toggleMic;
    }
  };

  render() {
    const { cameraOpen, audioOpen } = this.state;
    const {
      clientData,
      allAppointmentNotes,
      appointment,
      clientName,
      counsellorName,
      me,
    } = this.props;
    const resourceId = appointment.id;
    const { provider_type: providerType } = appointment;
    const userId = _.get(this.props, 'me.id');
    const timezone = this.props.me.preference
      ? this.props.me.preference.timezone
      : 'America/Toronto';
    const receivers = [];

    if (providerType === 'couples') {
      receivers.push(appointment.couples_appointment_detail.host);
      receivers.push(appointment.couples_appointment_detail.guest);
    } else {
      receivers.push(appointment.client);
    }

    if (!appointment.id || !me.id) {
      return <LoadingPanel />;
    }

    return (
      <div id="practitioner-video" className="row">
        <div
          style={{ paddingTop: '20px' }}
          className="col-lg-7 col-md-12 col-sm-12"
        >
          <Fullscreen
            enabled={this.state.isFull}
            onChange={isFull => this.setState({ isFull })}
          >
            <div className="parentVideoWrapper">
              <div className="wrapper">
                {!this.state.isFull && (
                  <ErrorOutline
                    color="#dab420"
                    className="vidyo-info"
                    onClick={() => this.toggle('showInfo')}
                  />
                )}
                {userId && providerType && (
                  <TwilioVideo
                    channel={this.props.match.params.id}
                    userId={userId}
                    callbackForTwillio={this.callbackForTwillio}
                    sessionType={providerType}
                    appointment={appointment}
                  />
                )}
                <div className="toggle-button-wrapper">
                  <div
                    className="toggle-camera"
                    role="presentation"
                    onClick={this.toggleCamera}
                  >
                    <div className="image-wrapper">
                      <img
                        src={cameraOpen ? CameraIMG : CameraOffIMG}
                        alt="camera"
                      />
                    </div>
                  </div>
                  <div
                    className="toggle-mic"
                    role="presentation"
                    onClick={this.toggleMic}
                  >
                    <div className="image-wrapper">
                      <img
                        src={audioOpen ? MicrophoneIMG : MicrophoneOffIMG}
                        alt="mic"
                      />
                    </div>
                  </div>
                  <div
                    className="reconnect"
                    role="presentation"
                    onClick={() => window.location.reload()}
                  >
                    <div className="image-wrapper">
                      <img src={ReloadIMG} alt="reload" />
                    </div>
                    <p>
                      {this.props.intl.formatMessage({
                        defaultMessage: 'Reconnect',
                      })}
                    </p>
                  </div>
                </div>
              </div>
              <VideoBar
                countdown={this.state.countdown}
                disconnect={this.disconnectAll}
                toggleFullscreen={() => this.toggle('isFull')}
                exitFullScreen={() => this.setState({ isFull: false })}
                receivers={receivers}
              />
            </div>
          </Fullscreen>
        </div>
        <div
          className="col-lg-5 col-md-12 col-sm-12"
          style={{ minHeight: '800px', paddingTop: 20 }}
        >
          <TwilioAudio appointment={appointment} />
          <Panel
            allAppointmentNotes={allAppointmentNotes}
            clientData={clientData}
            matchParamsID={this.props.match.params.id}
          />
        </div>
        <Snackbar
          bodyStyle={{ background: '#92b4ff' }}
          className="vidyo-snack"
          onRequestClose={() => false}
          open={this.state.snackbarOpen}
          message={this.props.intl.formatMessage({
            defaultMessage: 'This session has expired and will close in 1 hour',
          })}
        />
        {resourceId && counsellorName && clientName && appointment.chat && (
          <TextChat
            toggle={this.toggleChatBox}
            resourceId={resourceId}
            clientID={this.props.clientID}
            open={this.state.showChatBox}
            timezone={timezone}
            receivers={receivers}
            clientName={clientName}
            appointment={appointment}
          />
        )}
        <InfoModal
          open={this.state.showInfo}
          handleClose={() => this.toggle('showInfo')}
        />
      </div>
    );
  }
}

VidyoClient.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  fetchAppointment: PropTypes.func.isRequired,
  fetchClient: PropTypes.func.isRequired,
  fetchAppointmentNotes: PropTypes.func.isRequired,
  fetchSessionAnalytics: PropTypes.func.isRequired,
  updateAppointmentSession: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  appointment: PropTypes.object,
  intl: PropTypes.object.isRequired,
  clientData: PropTypes.object,
  me: PropTypes.object,
  clientID: PropTypes.number,
  allAppointmentNotes: PropTypes.array,
  updateAppointment: PropTypes.func.isRequired,
  clientName: PropTypes.string.isRequired,
  counsellorName: PropTypes.string.isRequired,
};

VidyoClient.defaultProps = {
  appointment: {},
  allAppointmentNotes: [],
  clientData: {},
  me: {},
  clientID: null,
};

function mapStateToProps(state) {
  const roles = _.get(state.user, 'roles');
  const appointment = _.get(state, 'session.appointment');
  const counsellor = _.get(appointment, 'counsellor');
  const client = _.get(appointment, 'client');

  return {
    appointment,
    roles,
    me: state.user,
    counsellorName: utils.nameParser(counsellor),
    clientName: utils.nameParser(client),
    clientID: client && client.id,
    clientData: client,
    allAppointmentNotes: state.session.appointment_notes,
    provider_yn: _.get(state.user, 'provider_yn'),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    updateAppointmentSession,
    fetchAppointment,
    fetchAppointmentNotes,
    fetchClient,
    fetchUser,
    fetchTodayAppointments,
    fetchSessionAnalytics,
    updateAppointment,
    fetchDocuments,
  })(
    reduxForm({
      form: 'SessionForm',
      enableReinitialize: true,
    })(VidyoClient),
  ),
);
